import { SelectableParameter } from "@interfaces/product";
import { ListVariationsValuesItem } from "..";

import style from "./ListVariationsValues.module.scss";

export function ListVariationsValues({
  data,
  parentId,
}: {
  data: SelectableParameter["values"];
  parentId: SelectableParameter["id"];
}) {
  return (
    <div className={style["list"]}>
      {data.map((item) => (
        <ListVariationsValuesItem
          key={item.id}
          data={item}
          parentId={parentId}
        />
      ))}
    </div>
  );
}
