import { Props } from "../../../Button";
import { DeleteIcon, EditIcon, SmallAddIcon } from "../../icons";
import cn from "classnames";

import style from "./Small.module.scss";

export function Small({
  onClick,
  type,
  className,
  disabled,
  icon,
}: Pick<Props, "onClick" | "type" | "className" | "disabled" | "icon">) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={cn(
        style["button"],
        type === "add" && style["button_add"],
        type === "delete" && style["button_delete"],
        type === "edit" && style["button_edit"],
        disabled && style["button_disabled"],
        className,
      )}
      onClick={onClick}
    >
      <div className={style["icon"]}>
        {icon ? (
          icon("")
        ) : (
          <>
            {type === "add" ? <SmallAddIcon /> : null}
            {type === "delete" ? <DeleteIcon /> : null}
            {type === "edit" ? <EditIcon /> : null}
          </>
        )}
      </div>
    </button>
  );
}
