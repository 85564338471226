import { Outlet } from "react-router-dom";

import style from "./CRMAuthLayout.module.scss";

export function CRMAuthLayout() {
  return (
    <div className={style["container"]}>
      <div className={style["limit"]}>
        <div className={style["logo"]}>
          <div className={style["imageLimit"]}>
            <img
              src="/images/logoMain.svg"
              alt="logo"
              className={style["image"]}
            />
          </div>
          <p className={style["label"]}>LevelUp</p>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
