import { useEffect, useRef } from "react";
import style from "./DeleteBookModal.module.scss";
import { useReferenceBookDeleteMutation } from "@RTKQuery/reference-book/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  referenceBook_deleteBooks_clearData,
  referenceBook_deleteBooks_deleteData,
  referenceBook_editBooks_clearData,
  referenceBook_editBooks_deleteData,
} from "@global-state/temporal-data/slice";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button, SubmitButton } from "@ui";
import { hideModal } from "@global-state/modal-state/slice";

const MODAL_NAME = "DeleteBookModal";

export function DeleteBookModal() {
  const [
    deleteDictionary,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useReferenceBookDeleteMutation();

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const listItems = useAppSelector(
    (state) => state.temporalData.reference_book.delete_books,
  );
  let listIds: number[] = [];

  if (activeMode === "single") {
    listIds = [listItems.find((item) => item.tag === "single")?.id || -1];
  } else {
    listIds = Array.from(new Set(listItems.map((item) => item.id)));
  }

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        if (activeMode === "single") {
          dispatch(
            referenceBook_deleteBooks_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editBooks_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
        } else {
          dispatch(referenceBook_deleteBooks_clearData());
          dispatch(referenceBook_editBooks_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeMode === "single") {
      dispatch(
        referenceBook_deleteBooks_deleteData({
          id: listIds[0],
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити вибрані довідники?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                deleteDictionary(listIds);
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
