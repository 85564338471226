import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import superadmin from "../slices/superadmin/slice";
import CRMUser from "../slices/CRMUser/slice";
import modalState from "../slices/modal-state/slice";
import temporalData from "../slices/temporal-data/slice";
import productPage from "../slices/product/slice";
import unloadingPage from "../slices/unloading/slice";
import { api } from "../querySlices";

export const store = configureStore({
  reducer: {
    superadmin,
    CRMUser,
    modalState,
    temporalData,
    productPage,
    unloadingPage,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type SliceAction<T> = {
  payload: T;
  type: string;
};
export type PartialExcept<T, K extends keyof T = keyof T> = Partial<
  Omit<T, K>
> &
  Pick<T, K>;
