import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";
import style from "./BookButtons.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import {
  referenceBook_deleteBooks_addData,
  referenceBook_editBooks_addData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function BookButtons({
  book,
  group,
}: {
  book: ReferenceBook;
  group: ReferenceBookGroup;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <Button
        type="edit"
        onClick={() => {
          dispatch(
            referenceBook_editBooks_addData([
              {
                id: book.id,
                name: book.name,
                group_id: group.id,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              name: "EditBookModal",
              mode: "single",
            }),
          );
        }}
      />
      <Button
        type="delete"
        onClick={() => {
          dispatch(
            referenceBook_deleteBooks_addData([
              {
                id: book.id,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              name: "DeleteBookModal",
              mode: "single",
            }),
          );
        }}
      />
    </div>
  );
}
