import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActions.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/modal-state/slice";

export function MultipleActions() {
  const delete_product_variation = useAppSelector(
    (state) => state.temporalData.product.selected_variation,
  );

  const dispatch = useAppDispatch();

  if (
    delete_product_variation.childIds.some((item) => item.tag === "multiple")
  ) {
    return (
      <div className={style["multipleActions"]}>
        <div className={style["button"]}>
          <Button
            type="delete"
            size="big"
            text="Видалити"
            onClick={() => {
              dispatch(
                showModal({
                  name: "DeleteVariationModal",
                  mode: "multiple",
                }),
              );
            }}
            className="px-[30px] max-sm:px-[20px]"
          />
        </div>
        <div className={style["button"]}>
          <Button
            type="add"
            size="big"
            text="Додати фото"
            variant="outlined"
            onClick={() => {
              dispatch(
                showModal({
                  name: "AddPicturesModal",
                }),
              );
            }}
            className="px-[30px] max-sm:px-[20px]"
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
