import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import style from "./CreateTenantForm.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { axiosAuth } from "@api";
import { setTenant } from "@global-state/superadmin/slice";
import { BasicInputProps, Input, Radio, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-z0-9-]+$/, "Тільки малі латинські літери, цифри та дефіс")
    .min(4, "Не менше 4 символів")
    .max(16, "Не більше 16 символів")
    .required("Назва домену - обов'язкове поле"),
});

export function CreateTenantForm({
  setIsSuccess,
}: {
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (submitingStatus === "success" && !activeModal) {
      setSubmitingStatus("idle");
    }
  }, [activeModal]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        accountType: "droper",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        setSubmitingStatus("load");

        axiosAuth("https://account-api.levelup.com.ua")
          .post("/account/tenant", {
            subdomain: values.name,
            account_type: values.accountType,
          })
          .then((response) => {
            setSubmitingStatus("success");
            setIsSuccess(true);
            dispatch(setTenant(response.data.tenants));
            actions.resetForm();
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
          });
      }}
    >
      {(props) => (
        <Form>
          <div className={style["container"]}>
            <div className={style["inputsBox"]}>
              <p className={style["prefix"]}>https://</p>
              <div className="grow">
                <Input<BasicInputProps>
                  formikName="name"
                  label=""
                  inputType="text"
                />
              </div>
              <p className={style["suffix"]}>.levelup.com.ua</p>
            </div>
            <div className={style["radioBox"]}>
              <Radio
                checked={props.values.accountType === "droper"}
                onChange={() => {
                  props.setFieldValue("accountType", "droper");
                }}
                label="Дропшипер"
              />
              <Radio
                checked={props.values.accountType === "supplier"}
                onChange={() => {
                  props.setFieldValue("accountType", "supplier");
                }}
                label="Постачальник"
              />
            </div>
          </div>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Створити нову CRM"
            successText="Успішно створено"
            errorText="Не вдалось створити"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
