import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useReferenceBookGroupUpdateMutation } from "@RTKQuery/reference-book/api";
import { useAppSelector } from "store/hooks";
import { Input, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва - обов'язкове поле"),
});

export function EditBookGroupForm() {
  const [
    updateDictionaryGroup,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useReferenceBookGroupUpdateMutation({
    fixedCacheKey: "edit-book-group",
  });

  const edit_group = useAppSelector(
    (state) => state.temporalData.reference_book.edit_group,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: edit_group?.group || "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        updateDictionaryGroup({
          id: edit_group?.id || -1,
          group: values.name,
        });
      }}
    >
      <Form>
        <div className="mb-[40px]">
          <Input formikName="name" label="Нова назва" inputType="text" />
        </div>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (error as unknown as { data: { error: string } }).data.error
              : ""
          }
        />
      </Form>
    </Formik>
  );
}
