import cn from "classnames";

import style from "./SwitchButton.module.scss";

export function SwitchButton({
  label,
  onChange,
  checked,
  alignLabel = "left",
}: {
  label?: JSX.Element | string;
  onChange: () => void;
  checked: boolean;
  alignLabel?: "right" | "left";
}) {
  return (
    <button
      className={cn(style["container"], checked && style["checked"])}
      onClick={onChange}
    >
      {alignLabel === "left" &&
        (typeof label === "string" ? (
          <p className={style["label"]}>{label}</p>
        ) : (
          label
        ))}
      <div className={style["switch"]}>
        <div className={style["core"]}></div>
      </div>
      {alignLabel === "right" &&
        (typeof label === "string" ? (
          <p className={style["label"]}>{label}</p>
        ) : (
          label
        ))}
    </button>
  );
}
