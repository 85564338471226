import { api } from "..";
import Category from "../../interfaces/category";

const categoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    categoryCreate: build.mutation<
      Category[],
      Omit<Category, "id" | "children">
    >({
      query: (category) => ({
        url: "category",
        method: "POST",
        body: category,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData("categoriesAll", undefined, updatedPost),
          );
        } catch {}
      },
    }),
    categoryUpdate: build.mutation<Category[], Omit<Category, "children">>({
      query: ({ id, ...category }) => ({
        url: `category/${id}`,
        method: "PUT",
        body: category,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData("categoriesAll", undefined, updatedPost),
          );
        } catch {}
      },
    }),
    categoryDelete: build.mutation<Category[], Category["id"]>({
      query: (categoriesIds) => ({
        url: `category?items=${categoriesIds}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData("categoriesAll", undefined, updatedPost),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCategoriesAllQuery,
  useCategoryCreateMutation,
  useCategoryUpdateMutation,
  useCategoryDeleteMutation,
} = categoriesApi;
