export function AnalyticsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.45 0.5C8.5823 0.5 7.05 2.0323 7.05 3.9C7.05 4.77884 7.38932 5.58374 7.94316 6.18936L4.49336 10.8079C4.22342 10.7382 3.94071 10.7 3.65 10.7C1.7823 10.7 0.25 12.2323 0.25 14.1C0.25 15.9677 1.7823 17.5 3.65 17.5C5.5177 17.5 7.05 15.9677 7.05 14.1C7.05 13.1368 6.64177 12.2636 5.99082 11.643L9.36924 7.1207C9.70926 7.23588 10.0727 7.3 10.45 7.3C10.7117 7.3 10.9667 7.26889 11.212 7.21201L13.6724 11.2711C12.7574 11.8826 12.15 12.925 12.15 14.1C12.15 15.9677 13.6823 17.5 15.55 17.5C17.4177 17.5 18.95 15.9677 18.95 14.1C18.95 13.1368 18.5418 12.2636 17.8908 11.643L21.2692 7.1207C21.6093 7.23588 21.9727 7.3 22.35 7.3C24.2177 7.3 25.75 5.7677 25.75 3.9C25.75 2.0323 24.2177 0.5 22.35 0.5C20.4823 0.5 18.95 2.0323 18.95 3.9C18.95 4.77884 19.2893 5.58374 19.8432 6.18936L16.3934 10.8079C16.1234 10.7382 15.8407 10.7 15.55 10.7C15.4718 10.7 15.3945 10.7042 15.3176 10.7099L12.7211 6.42341C13.4131 5.7996 13.85 4.89767 13.85 3.9C13.85 2.0323 12.3177 0.5 10.45 0.5ZM10.45 2.2C11.3989 2.2 12.15 2.95105 12.15 3.9C12.15 4.84896 11.3989 5.6 10.45 5.6C9.50105 5.6 8.75 4.84896 8.75 3.9C8.75 2.95105 9.50105 2.2 10.45 2.2ZM22.35 2.2C23.2989 2.2 24.05 2.95105 24.05 3.9C24.05 4.84896 23.2989 5.6 22.35 5.6C21.4011 5.6 20.65 4.84896 20.65 3.9C20.65 2.95105 21.4011 2.2 22.35 2.2ZM3.65 12.4C4.59895 12.4 5.35 13.1511 5.35 14.1C5.35 15.049 4.59895 15.8 3.65 15.8C2.70105 15.8 1.95 15.049 1.95 14.1C1.95 13.1511 2.70105 12.4 3.65 12.4ZM15.55 12.4C16.4989 12.4 17.25 13.1511 17.25 14.1C17.25 15.049 16.4989 15.8 15.55 15.8C14.6011 15.8 13.85 15.049 13.85 14.1C13.85 13.1511 14.6011 12.4 15.55 12.4Z"
      />
    </svg>
  );
}
