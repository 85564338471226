import { api } from "..";
import ReferenceBookGroup, {
  ReferenceBook,
} from "../../interfaces/refence-book";

const referenceBookApi = api.injectEndpoints({
  endpoints: (build) => ({
    referenceBookCreate: build.mutation<
      ReferenceBookGroup[],
      { group_id: ReferenceBookGroup["id"]; name: ReferenceBook["name"] }
    >({
      query: (referenceBook) => ({
        url: "dictionary",
        method: "POST",
        body: [referenceBook],
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookGroupCreate: build.mutation<
      ReferenceBookGroup[],
      ReferenceBookGroup["group"]
    >({
      query: (name) => ({
        url: "dictionary/group",
        method: "POST",
        body: [{ name }],
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookUpdate: build.mutation<
      ReferenceBookGroup[],
      {
        id: ReferenceBook["id"];
        group_id?: ReferenceBookGroup["id"];
        name?: ReferenceBook["name"];
      }[]
    >({
      query: (referenceBooks) => ({
        url: "dictionary",
        method: "PUT",
        body: referenceBooks,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookGroupUpdate: build.mutation<
      { id: number; name: string }[],
      Omit<ReferenceBookGroup, "items">
    >({
      query: (referenceBookGroup) => ({
        url: "dictionary/group",
        method: "PUT",
        body: [
          {
            id: referenceBookGroup.id,
            name: referenceBookGroup.group,
          },
        ],
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("referenceBookAll", undefined, (draft) => {
              const idMutableItem = draft.findIndex(
                (item) => item.id === updatedPost[0].id,
              );

              draft[idMutableItem].group = updatedPost[0].name;
            }),
          );
        } catch {}
      },
    }),
    referenceBookDelete: build.mutation<
      ReferenceBookGroup[],
      ReferenceBook["id"][]
    >({
      query: (referenceBooksIds) => {
        const listItem = referenceBooksIds.map((id) => `items=${id}`).join("&");

        return {
          url: `dictionary?${listItem}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookGroupDelete: build.mutation<
      ReferenceBookGroup[],
      ReferenceBookGroup["id"]
    >({
      query: (referenceBooksIds) => {
        return {
          url: `dictionary/group?items=${referenceBooksIds}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useReferenceBookAllQuery,
  useReferenceBookCreateMutation,
  useReferenceBookGroupCreateMutation,
  useReferenceBookUpdateMutation,
  useReferenceBookGroupUpdateMutation,
  useReferenceBookDeleteMutation,
  useReferenceBookGroupDeleteMutation,
} = referenceBookApi;
