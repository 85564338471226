import {
  ListCharacteristics,
  ListVariations,
  MultipleActionsParameters,
} from "../../../../components/catalog/products-template";

import style from "./ProductsTemplateOnePage.module.scss";

export function ProductsTemplateOnePage() {
  return (
    <div className={style["page"]}>
      <div className={style["container"]}>
        <ListVariations />
      </div>
      <div className={style["container"]}>
        <ListCharacteristics />
      </div>
      <MultipleActionsParameters />
    </div>
  );
}
