import ProductsTemplate from "@interfaces/products-template";
import style from "./TemplateButtons.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import {
  productsTemplate_delete_addData,
  productsTemplate_edit_setData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function TemplateButtons({
  template,
}: {
  template: Pick<ProductsTemplate, "id" | "name">;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <Button
        type="delete"
        onClick={() => {
          dispatch(
            productsTemplate_delete_addData([
              {
                id: template.id,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              name: "DeleteTemplateModal",
              mode: "single",
            }),
          );
        }}
      />
      <Button
        type="edit"
        onClick={() => {
          dispatch(
            productsTemplate_edit_setData({
              id: template.id,
              name: template.name,
            }),
          );
          dispatch(
            showModal({
              name: "EditTemplateModal",
            }),
          );
        }}
      />
    </div>
  );
}
