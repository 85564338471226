import { useParams } from "react-router-dom";
import style from "./CurrentTemplate.module.scss";
import { useProductsTemplateAllQuery } from "@RTKQuery/products-template/api";
import { BackButton } from "@ui";

export function CurrentTemplate() {
  const { data } = useProductsTemplateAllQuery();

  const { id: idTemplate } = useParams();

  const template = data?.find((item) => item.id === Number(idTemplate));

  if (idTemplate) {
    return (
      <div className={style["container"]}>
        <BackButton />
        <p className={style["currentPage"]}>{template?.name}</p>
      </div>
    );
  } else {
    return (
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Шаблони</p>
      </div>
    );
  }
}
