import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import cn from "classnames";
import style from "./Branch.module.scss";
import Category from "@interfaces/category";
import { useAppDispatch } from "store/hooks";
import { Button, SpoilerButton } from "@ui";
import {
  category_add_setData,
  category_delete_setData,
  category_edit_setData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function Branch({
  data,
  treeIds,
  actions = true,
}: {
  data: Category;
  treeIds: string;
  actions?: boolean;
}) {
  const openDefault = window.localStorage
    .getItem("treeIdsCategory")
    ?.split("/")
    .includes(String(data.id));

  const [isOpen, setIsOpen] = useState<boolean>(!!openDefault);

  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  const dispatch = useAppDispatch();

  if (categoryId && data.id === +categoryId) {
    window.localStorage.setItem("categoryProductsPage", data.id + "");
  }

  useEffect(() => {
    if (categoryId && data.id === +categoryId) {
      window.localStorage.setItem(
        "treeIdsCategory",
        treeIds.replace(String(data.id), ""),
      );
    }
  }, [categoryId]);

  return (
    <div className={style["branchTree"]}>
      <div
        className={cn(
          style["branch"],
          categoryId && data.id === +categoryId && style["branch_active"],
        )}
      >
        <Link
          className={style["link"]}
          to={
            crm +
            "/category/" +
            data.id +
            "?limit=" +
            (queryParams.get("limit") || "1") +
            "&offset=0"
          }
        >
          <p title={data.name["uk"]} className={style["nameCategory"]}>
            {data.name["uk"]}
          </p>
        </Link>
        {actions && (
          <div className={style["actions"]}>
            <Button
              type="add"
              onClick={() => {
                dispatch(category_add_setData(data.id));
                dispatch(
                  showModal({
                    name: "AddCategoryModal",
                  }),
                );
              }}
            />
            <Button
              type="edit"
              onClick={() => {
                dispatch(category_edit_setData(data));
                dispatch(
                  showModal({
                    name: "EditCategoryModal",
                  }),
                );
              }}
            />
            <Button
              type="delete"
              onClick={() => {
                dispatch(category_delete_setData(data));
                dispatch(
                  showModal({
                    name: "DeleteCategoryModal",
                  }),
                );
              }}
            />
            {data.children.length > 0 && (
              <SpoilerButton
                isOpen={isOpen}
                onClick={() => setIsOpen((state) => !state)}
                className={style["customSpoilerButton"]}
              />
            )}
          </div>
        )}
      </div>
      <div className={style["divider"]}></div>
      {isOpen && data.children.length > 0 && (
        <div className="ml-[10px] flex flex-col gap-[5px]">
          {data.children.map((item) => (
            <Branch
              key={item.id}
              data={item}
              treeIds={treeIds + "/" + item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}
