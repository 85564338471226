export function FilterSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M14.75 2.5H13.25M14.75 7H11M14.75 11.5H11M4.25 13V8.1709C4.25 8.0149 4.25 7.9369 4.23468 7.86228C4.2211 7.79613 4.19863 7.73207 4.16789 7.67185C4.13323 7.60405 4.08451 7.54315 3.98704 7.42127L1.51296 4.3287C1.4155 4.20687 1.36677 4.14596 1.33211 4.07813C1.30137 4.01795 1.2789 3.95389 1.26532 3.88769C1.25 3.81308 1.25 3.73508 1.25 3.57906V2.2C1.25 1.77996 1.25 1.56994 1.33174 1.40951C1.40365 1.26839 1.51839 1.15365 1.65951 1.08174C1.81994 1 2.02996 1 2.45 1H9.05C9.47007 1 9.68008 1 9.8405 1.08174C9.98165 1.15365 10.0963 1.26839 10.1682 1.40951C10.25 1.56994 10.25 1.77996 10.25 2.2V3.57906C10.25 3.73508 10.25 3.81308 10.2347 3.88769C10.2211 3.95389 10.1986 4.01795 10.1679 4.07813C10.1332 4.14596 10.0845 4.20687 9.98705 4.3287L7.51295 7.42127C7.41553 7.54315 7.36677 7.60405 7.33212 7.67185C7.30137 7.73207 7.27888 7.79613 7.2653 7.86228C7.25 7.9369 7.25 8.0149 7.25 8.1709V10.75L4.25 13Z"
        stroke="#4674B8"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
