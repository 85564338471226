import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import style from "./DeleteTenantModal.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { DeleteTenantForm } from "@forms";
import { hideModal } from "@global-state/modal-state/slice";

const MODAL_NAME = "DeleteTenantModal";

export function DeleteTenantModal() {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const activeModal = useAppSelector((state) => state.modalState.modalName);
  const tenant_delete = useAppSelector(
    (state) => state.temporalData.tenant.delete,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess && !activeModal) {
      refSetTimeout.current = setTimeout(() => {
        hiddenModal();
      }, 700);
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);
    setIsSuccess(false);

    dispatch(hideModal());
  }

  return (
    <div
      className={cn(style["backdrop"], activeModal === MODAL_NAME && "flex")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          hiddenModal();
        }
      }}
    >
      <div className={style["modalContent"]}>
        <div className={style["titleBox"]}>
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="exclamation-circle"
            width="24px"
            height="24px"
            fill="#faad14"
            aria-hidden="true"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
            <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
          </svg>
          <p className={style["title"]}>Увага!</p>
        </div>
        <p className={style["message"]}>
          Будуть видалені всі дані по замовленням, заявкам, покупцям та інші
          дані з CRM{" "}
          <span className="font-bold">{tenant_delete?.subdomain}</span>.
          Можливість входу буде заблокована. Ви дійсно хочете видалити цю CRM?
        </p>
        <DeleteTenantForm setIsSuccess={setIsSuccess} />
      </div>
    </div>
  );
}
