import { useAppSelector } from "store/hooks";
import { Item } from "./components";

import style from "./List.module.scss";

export function List() {
  const tenantsDeleted = useAppSelector(
    (state) => state.superadmin.deleted_tenants,
  );

  return (
    <table className={style["table"]}>
      <thead className={style["thead"]}>
        <tr className={style["row"]}>
          <th className={style["col"]}>Назва</th>
          <th className={style["col"]}></th>
        </tr>
      </thead>
      <tbody>
        {tenantsDeleted.map((tenant) => (
          <Item key={tenant.uuid} data={tenant} />
        ))}
      </tbody>
    </table>
  );
}
