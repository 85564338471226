import cn from "classnames";

import style from "./Checkbox.module.scss";
import { CheckMarkSvg } from ".";

export function Checkbox({
  label,
  onChange,
  checked,
  alignLabel = "right",
}: {
  label?: JSX.Element | string | ((isChecked: boolean) => JSX.Element);
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: boolean) => void;
  checked: boolean;
  alignLabel?: "right" | "left";
}) {
  return (
    <label
      className={cn(
        style["container"],
        checked ? style["checked_on"] : style["checked_off"],
      )}
    >
      {alignLabel === "left" &&
        (typeof label === "string" ? (
          <p className={style["label"]}>{label}</p>
        ) : typeof label === "function" ? (
          label(checked)
        ) : (
          label
        ))}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div className={style["externalRectangle"]}>
        <div className={style["internalRectangle"]}>
          <CheckMarkSvg />
        </div>
      </div>
      {alignLabel === "right" &&
        (typeof label === "string" ? (
          <p className={style["label"]}>{label}</p>
        ) : typeof label === "function" ? (
          label(checked)
        ) : (
          label
        ))}
    </label>
  );
}
