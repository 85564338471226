import cn from "classnames";
import style from "./InputNoFormik.module.scss";
import { RxCrossCircled } from "react-icons/rx";

export function InputNoFormik({
  value,
  setValue,
  label,
  placeholder,
  disabled,
  error,
}: {
  value: string;
  setValue: (value: string) => void;
  label?: string | JSX.Element;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
}) {
  return (
    <div className={cn(disabled && style["disabled"])}>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div
        className={cn(
          style["container"],
          error ? style["error_on"] : style["error_off"],
        )}
      >
        <input
          className={style["input"]}
          type="text"
          placeholder={placeholder || ""}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <button
          disabled={disabled}
          className={cn(
            style["clearButton"],
            disabled && style["clearButton_disabled"],
          )}
        >
          {value ? (
            <RxCrossCircled
              className={style["icon"]}
              onClick={() => {
                setValue("");
              }}
            />
          ) : null}
        </button>
      </div>
    </div>
  );
}
