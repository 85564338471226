import cn from "classnames";

import style from "./PageButton.module.scss";

export function PageButton({
  activePage,
  numberPage,
  onClick,
  className,
}: {
  activePage: number;
  numberPage: number;
  onClick: (numberPage: number) => void;
  className?: string;
}) {
  return (
    <button
      className={cn(
        style["pageButton"],
        activePage === numberPage && style["pageButton_active"],
        className,
      )}
      onClick={() => {
        onClick(numberPage);
      }}
    >
      <span className={style["value"]}>{numberPage + 1}</span>
    </button>
  );
}
