export function ReferencesSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.5 18C14.1945 18 18 14.1945 18 9.5C18 4.80558 14.1945 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1945 4.80558 18 9.5 18Z"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path d="M9.36113 14.4443C9.88275 14.4443 10.3056 14.0215 10.3056 13.4999C10.3056 12.9782 9.88275 12.5554 9.36113 12.5554C8.83951 12.5554 8.41669 12.9782 8.41669 13.4999C8.41669 14.0215 8.83951 14.4443 9.36113 14.4443Z" />
      <path
        d="M9.36111 11.6111C9.36111 11.4537 9.36111 11.2963 9.36111 11.1389C9.36111 11.1389 9.36111 9.72222 11.25 8.77778C13.1389 7.83333 12.6667 5 9.83333 5C7 5 7 7.36111 7 7.36111V7.83333"
        strokeWidth="1.6"
      />
    </svg>
  );
}
