import {
  referenceBook_deleteBooks_addData,
  referenceBook_deleteBooks_deleteData,
  referenceBook_editBooks_addData,
  referenceBook_editBooks_deleteData,
} from "@global-state/temporal-data/slice";
import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function BookCheckbox({
  book,
  group,
}: {
  book: ReferenceBook;
  group: ReferenceBookGroup;
}) {
  const delete_books_ids = useAppSelector(
    (state) => state.temporalData.reference_book.delete_books,
  ).map((item) => item.id);
  const edit_books_ids = useAppSelector(
    (state) => state.temporalData.reference_book.edit_books,
  ).map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        delete_books_ids.includes(book.id) && edit_books_ids.includes(book.id)
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteBooks_addData([
              {
                id: book.id,
                tag: "multiple",
              },
            ]),
          );
          dispatch(
            referenceBook_editBooks_addData([
              {
                id: book.id,
                name: book.name,
                group_id: group.id,
                tag: "multiple",
              },
            ]),
          );
        } else {
          dispatch(
            referenceBook_deleteBooks_deleteData({
              id: book.id,
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editBooks_deleteData({
              id: book.id,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
