import { Link } from "react-router-dom";
import { BookButtons, BookCheckbox } from "./components";
import style from "./ItemBook.module.scss";
import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";

export function ItemBook({
  book,
  group,
}: {
  book: ReferenceBook;
  group: ReferenceBookGroup;
}) {
  const crm = window.localStorage.getItem("subdomain");

  return (
    <div className={style["book"]}>
      <div className={style["box"]}>
        <BookCheckbox book={book} group={group} />
        <Link
          to={
            process.env.NODE_ENV === "development"
              ? `/${crm}/reference-book/${group.id}/values/${book.id}`
              : `/reference-book/${group.id}/values/${book.id}`
          }
          title={book.name}
          className={style["link"]}
        >
          {book.name}
        </Link>
      </div>
      <BookButtons book={book} group={group} />
    </div>
  );
}
