import { Outlet } from "react-router-dom";

import { TopPanel } from "../../../../components/catalog/products-template";

import style from "./ProductTemplateLayout.module.scss";
import {
  AddParameterModal,
  AddTemplateModal,
  DeleteParameterModal,
  DeleteTemplateModal,
  EditParameterModal,
  EditTemplateModal,
} from "@modals";

export function ProductTemplateLayout() {
  return (
    <main className={style["layout"]}>
      <div className={style["container"]}>
        <TopPanel />
        <Outlet />
        <>
          <AddTemplateModal />
          <AddParameterModal />
          <DeleteTemplateModal />
          <DeleteParameterModal />
          <EditTemplateModal />
          <EditParameterModal />
        </>
      </div>
    </main>
  );
}
