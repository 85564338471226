import cn from "classnames";

import style from "./AllCharacteristicsCheckbox.module.scss";
import ProductsTemplate from "@interfaces/products-template";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  parameters_delete_clearData,
  parameters_delete_setData,
} from "@global-state/temporal-data/slice";

export function AllCharacteristicsCheckbox({
  characteristics,
}: {
  characteristics: ProductsTemplate["characteristics"] | undefined;
}) {
  const delete_characteristic = useAppSelector(
    (state) => state.temporalData.parameters.delete_characteristic,
  );

  const dispatch = useAppDispatch();

  const checked =
    characteristics?.length ===
    delete_characteristic.filter((item) => item.tag === "multiple").length;

  return (
    <label
      className={cn(
        style["container"],
        checked ? style["checked_on"] : style["checked_off"],
      )}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) {
            dispatch(
              parameters_delete_setData({
                data:
                  characteristics?.map((item) => ({
                    id: item.id,
                    tag: "multiple",
                  })) || [],
                parameterType: "characteristic",
              }),
            );
          } else {
            dispatch(parameters_delete_clearData("characteristic"));
          }
        }}
        className="hidden"
      />
      <div className={style["externalRectangle"]}>
        <div className={style["internalRectangle"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12px"
            height="10px"
            viewBox="0 0 12 10"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7933 0.252801C12.0689 0.589878 12.0689 1.13637 11.7933 1.47345L5.23356 9.49439C4.68224 10.1686 3.78836 10.1685 3.23703 9.49439L0.206746 5.78908C-0.0689154 5.45202 -0.0689154 4.90549 0.206746 4.56844C0.482415 4.23139 0.929352 4.23139 1.20502 4.56844L4.2353 8.27376L10.795 0.252801C11.0707 -0.084267 11.5176 -0.084267 11.7933 0.252801Z"
              fill="#4674B8"
            />
          </svg>
        </div>
      </div>
    </label>
  );
}
