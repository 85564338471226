import React from "react";
import cn from "classnames";
import style from "./GridContainer.module.scss";

export interface Props {
  children: React.ReactNode;
  columns: number;
}

export function GridContainer({ children, columns }: Props) {
  return (
    <ul
      className={cn(style["gridContainer"], "scroll")}
      style={
        {
          "--col-count": columns,
        } as React.CSSProperties
      }
    >
      {children}
    </ul>
  );
}
