import { BackButton } from "@ui";
import style from "./TopPanel.module.scss";
import { ProductSubmitButton } from "../ProductSubmitButton";
import { useParams } from "react-router-dom";

export function TopPanel({
  basicFormRef,
}: {
  basicFormRef: React.RefObject<HTMLButtonElement>;
}) {
  const { productId } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  return (
    <div className={style["topPanel"]}>
      <div className={style["container"]}>
        <BackButton url={crm + "/category/"} />
        <p className={style["currentPage"]}>
          {productId ? "Редагувати товар" : "Додати товар"}
        </p>
      </div>
      <div className={style["button"]}>
        <ProductSubmitButton basicFormRef={basicFormRef} />
      </div>
    </div>
  );
}
