import {
  TableValues,
  MultipleActionsValues,
} from "../../../../components/catalog/reference-book";

import style from "./ReferenceBookValuesPage.module.scss";

export function ReferenceBookValuesPage() {
  return (
    <div className={style["page"]}>
      <TableValues />
      <MultipleActionsValues />
    </div>
  );
}
