import style from "./HistoryBalanceHead.module.scss";

export function HistoryBalanceHead() {
  return (
    <div className={style["gridHead"]}>
      <div className={style["headCell"]}>Дата</div>
      <div className={style["headCell"]}>Тип</div>
      <div className={style["headCell"]}>Сума</div>
    </div>
  );
}
