import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "../../store/store";
import CRMUser from "./inteface";
import { SetCRMUserAllInterface } from "./dto/SetCRMUserAll.dto";

const initialState: CRMUser = {
  user: {
    first_name: "",
    lang: "",
    last_name: "",
    tz: "",
    uuid: "",
    email: "",
    phone: "",
  },
  role: {
    uuid: "",
    is_custom: false,
    is_system: false,
    role_name: "",
    role_title: "",
    role_description: "",
    permissions: [],
  },
  profile_status: {
    is_active: false,
    is_verified: false,
    is_visible: false,
  },
};

const CRMUserSlice = createSlice({
  initialState,
  name: "CRMUser",
  reducers: {
    setCRMUserAll(state, action: SliceAction<SetCRMUserAllInterface>) {
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.profile_status = action.payload.profile_status;
    },

    clearCRMUserAll: (state) => {
      state.user = {
        first_name: "",
        lang: "",
        last_name: "",
        tz: "",
        uuid: "",
        email: "",
        phone: "",
      };
      state.role = {
        uuid: "",
        is_custom: false,
        is_system: false,
        role_name: "",
        role_title: "",
        role_description: "",
        permissions: [],
      };
      state.profile_status = {
        is_active: false,
        is_verified: false,
        is_visible: false,
      };
    },
  },
});

const { reducer, actions } = CRMUserSlice;

export default reducer;

export const {
  setCRMUserAll,

  clearCRMUserAll,
} = actions;
