import { FaUser } from "react-icons/fa6";
import { LogoutButton } from "..";

import style from "./ProfileBurger.module.scss";
import { useAppSelector } from "store/hooks";

export function ProfileBurger() {
  const user = useAppSelector((state) => state.CRMUser.user);
  const roleName = useAppSelector((state) => state.CRMUser.role.role_name);

  return (
    <div className={style["profile"]}>
      <div className={style["info"]}>
        <div className={style["role"]}>{roleName.toLowerCase()}</div>
        <div className={style["box"]}>
          <div className={style["avatar"]}>
            <FaUser className="h-full w-full fill-[#bcc7db]" />
          </div>
          <div
            className={style["username"]}
            title={user.last_name + " " + user.first_name}
          >
            {user.last_name} {user.first_name}
          </div>
        </div>
      </div>
      <div className={style["button"]}>
        <LogoutButton />
      </div>
    </div>
  );
}
