import {
  Link,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Balance,
  Burger,
  ProfileBurger,
  ProfileButton,
  SubMenu,
  ThemeSwitch,
} from "../../../../components/menu";
import {
  AnalyticsSvg,
  CatalogSvg,
  ChatsSvg,
  BalanceSvg,
  SalesSvg,
  SettingsSvg,
} from "../../../../components/menu/svg/main";
import {
  ProductsSvg,
  ProductsTemplateSvg,
  ReferencesSvg,
  UnloadingSvg,
} from "../../../../components/menu/svg/catalog";
import cn from "classnames";
import style from "./CRMMainLayout.module.scss";
import { useAppDispatch } from "store/hooks";
import { clearAllTemporalData } from "@global-state/temporal-data/slice";
import { hideModal } from "@global-state/modal-state/slice";

export function CRMMainLayout() {
  const [currentPage, setCurrentPage] = useState<string>("");

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(hideModal());
    dispatch(clearAllTemporalData());
  }, [location]);

  return (
    <div className={style["container"]}>
      <div className={style["limit"]}>
        <nav className={cn(style["navigate"], "max-sm:!hidden")}>
          <div className={style["section"]}>
            <Link to={`${crm}/category`}>
              <img
                src="/images/logoMain.svg"
                alt="logo"
                className={style["logo"]}
              />
            </Link>
            <Balance />
            <div className={style["liksBox"]}>
              <SalesLinks crm={crm} />
              <ChatsLinks crm={crm} />
              <CatalogLinks crm={crm} />
            </div>
          </div>
          <div className={style["divider"]}></div>
          <div className={style["section"]}>
            <div className={style["liksBox"]}>
              <BalanceLinks crm={crm} />
              <AnalyticsLinks crm={crm} />
              <SettingsLinks crm={crm} />
            </div>
            <div className={style["divider"]}></div>
            <ThemeSwitch />
            <div className={style["divider"]}></div>
            <ProfileButton />
          </div>
        </nav>
        <div className={style["backdrop"]}></div>
        <nav className={cn(style["navigateMobile"], "max-sm:!flex")}>
          <div className="flex items-center gap-[20px]">
            <Link to={`${crm}/category`}>
              <img
                src="/images/logoMain.svg"
                alt="logo"
                className={style["logo"]}
              />
            </Link>
            <div className={style["currentPage"]}>{currentPage}</div>
          </div>
          <div className="flex items-center gap-[17px]">
            <Balance />
            <Burger>
              <>
                <div className="mb-[40px] flex flex-col gap-[10px]">
                  <SalesLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <ChatsLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <CatalogLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <BalanceLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <AnalyticsLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <SettingsLinks crm={crm} setCurrentPage={setCurrentPage} />
                </div>
                <div className="mb-[22px] flex items-center justify-between px-[20px]">
                  <p className="text-[16px] font-medium text-[#6A6A6A]">
                    Змінити тему
                  </p>
                  <ThemeSwitch />
                </div>
                <ProfileBurger />
              </>
            </Burger>
          </div>
        </nav>
      </div>
      <Outlet />
    </div>
  );
}

function CatalogLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const location = useLocation();
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams();

  const activeCategoryProductsPage =
    categoryId || localStorage.getItem("categoryProductsPage") || 0;
  const activeLimitProductsPage =
    (location.pathname.includes("category") && searchParams.get("limit")) ||
    localStorage.getItem("limitProductsPage") ||
    20;
  const activeOffsetProductsPage =
    (location.pathname.includes("category") && searchParams.get("offset")) ||
    localStorage.getItem("offsetProductsPage") ||
    0;
  const activeSearchProductsPage =
    (location.pathname.includes("category") && searchParams.get("search")) ||
    localStorage.getItem("searchProductsPage");

  return (
    <SubMenu
      icon={<CatalogSvg />}
      name="Каталог"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Товари",
          link: `${crm}/category/${activeCategoryProductsPage}/?limit=${activeLimitProductsPage}&offset=${activeOffsetProductsPage}${activeSearchProductsPage ? `&search=${activeSearchProductsPage}` : ""}`,
          icon: <ProductsSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 15 : 9,
        },
        {
          text: "Шаблони товарів",
          link: `${crm}/products-template`,
          icon: <ProductsTemplateSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 24 : 18,
        },
        {
          text: "Довідники",
          link: `${crm}/reference-book`,
          icon: <ReferencesSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 21 : 15,
        },
        {
          text: "Вигрузки",
          link: `${crm}/unloading/0`,
          icon: <UnloadingSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 16 : 8,
        },
      ]}
    />
  );
}

function SalesLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<SalesSvg />}
      name="Продажі"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Замовлення",
          link: `${crm}/orders`,
          icon: <SalesSvg />,
        },
        {
          text: "База покупців",
          link: `${crm}/customers`,
          icon: <SalesSvg />,
        },
      ]}
    />
  );
}

function ChatsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<ChatsSvg />}
      name="Чати"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Клієнти",
          link: `${crm}/clients`,
          icon: <ChatsSvg />,
        },
        {
          text: "Постачальники",
          link: `${crm}/suppliers`,
          icon: <ChatsSvg />,
        },
      ]}
    />
  );
}

function BalanceLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<BalanceSvg />}
      name="Баланс"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Баланс",
          link: `${crm}/balance`,
          icon: <BalanceSvg />,
        },
      ]}
    />
  );
}

function AnalyticsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<AnalyticsSvg />}
      name="Аналітика"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Товари",
          link: `${crm}/analytics/products`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Замовлення",
          link: `${crm}/analytics/order`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Менеджери",
          link: `${crm}/analytics/managers`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Постачальники",
          link: `${crm}/analytics/suppliers`,
          icon: <AnalyticsSvg />,
        },
      ]}
    />
  );
}

function SettingsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<SettingsSvg />}
      name="Налаштування"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Основні",
          link: `${crm}/settings`,
          icon: <SettingsSvg />,
        },
        {
          text: "Замовлення",
          link: `${crm}/settings/order`,
          icon: <SettingsSvg />,
        },
        {
          text: "Джерела",
          link: `${crm}/settings/sources`,
          icon: <SettingsSvg />,
        },
        {
          text: "Комунікация",
          link: `${crm}/settings/communication`,
          icon: <SettingsSvg />,
        },
        {
          text: "Фінанси",
          link: `${crm}/settings/finance`,
          icon: <SettingsSvg />,
        },
        {
          text: "Ролі",
          link: `${crm}/settings/roles`,
          icon: <SettingsSvg />,
        },
        {
          text: "Стан рахунку",
          link: `${crm}/settings/account-status`,
          icon: <SettingsSvg />,
        },
      ]}
    />
  );
}
