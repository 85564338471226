import { Link } from "react-router-dom";
import { RegisterForm } from "@forms";

import style from "./RegisterPage.module.scss";

export function RegisterPage() {
  return (
    <main className={style["page"]}>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>
          Підключіться сьогодні! <br /> Повний функціонал без <br /> оплат до 30
          днів
        </h3>
        <RegisterForm />
        <p className="text-center">
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/auth/login"
                : "/auth/login"
            }
            className={style["loginLink"]}
          >
            В мене вже є акаунт
          </Link>
        </p>
      </div>
    </main>
  );
}
