export function ProductsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5834 5.57633C15.5834 5.19756 15.3694 4.85129 15.0306 4.6819L8.94723 1.64023C8.66571 1.49947 8.33433 1.49947 8.05281 1.64023L1.96947 4.6819C1.63069 4.85129 1.41669 5.19755 1.41669 5.57633V11.4236C1.41669 11.8024 1.63069 12.1486 1.96947 12.318L8.05281 15.3597C8.33433 15.5005 8.66571 15.5005 8.94723 15.3597L15.0306 12.318C15.3694 12.1486 15.5834 11.8024 15.5834 11.4236V5.57633Z"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M1.74359 5.23071L8.71795 8.71789"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71796 14.8205L8.71796 8.7179"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8205 5.23071L8.71796 8.71789"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0416 3.1875L4.95831 6.72917"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
