import { LoginCRMForm } from "@forms";
import style from "./LoginCRMPage.module.scss";

export function LoginCRMPage() {
  return (
    <main className={style["page"]}>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>Вхід до CRM</h3>
        <LoginCRMForm />
      </div>
    </main>
  );
}
