import { Characteristic } from "@interfaces/products-template";
import style from "./CharacteristicButtons.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import {
  parameters_delete_addData,
  parameters_edit_setData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function CharacteristicButtons({
  characteristic,
}: {
  characteristic: Characteristic;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <Button
        type="delete"
        onClick={() => {
          dispatch(
            parameters_delete_addData({
              data: [
                {
                  id: characteristic.id,
                  tag: "single",
                },
              ],
              parameterType: "characteristic",
            }),
          );
          dispatch(
            showModal({
              name: "DeleteParameterModal",
              mode: "single",
            }),
          );
        }}
      />
      <Button
        type="edit"
        onClick={() => {
          dispatch(parameters_edit_setData(characteristic));
          dispatch(
            showModal({
              name: "EditParameterModal",
              mode: "characteristic",
            }),
          );
        }}
      />
    </div>
  );
}
