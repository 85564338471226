import {
  MultipleActions,
  ProductVariationsList,
  ProductVariationsWrapper,
} from "./components";

export function ProductVariations() {
  return (
    <ProductVariationsWrapper>
      <>
        <ProductVariationsList />
        <MultipleActions />
      </>
    </ProductVariationsWrapper>
  );
}
