import { SelectableParameter } from "@interfaces/product";
import { ListCharacteristicsItem } from "..";
import cn from "classnames";
import style from "./ListCharacteristics.module.scss";

export function ListCharacteristics({ data }: { data: SelectableParameter[] }) {
  const listElement: JSX.Element[] = [];

  data.forEach((item, i) => {
    listElement.push(<ListCharacteristicsItem key={item.id} data={item} />);
    if (i !== data.length - 1) {
      listElement.push(
        <div key={item.id + "divider_left"} className={style["divider"]}></div>,
      );
      listElement.push(
        <div
          key={item.id + "divider_right"}
          className={style["divider"]}
        ></div>,
      );
    }
  });

  return (
    <div className={style["container"]}>
      <div className={cn(style["scrollContainer"], "scroll")}>
        <div className={style["gridTable"]}>{listElement}</div>
      </div>
    </div>
  );
}
