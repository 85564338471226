import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Tbody, Thead } from "./components";
import cn from "classnames";
import style from "./ProductTable.module.scss";
import { useProductsAllQuery } from "@RTKQuery/products/api";

export function ProductTable() {
  const [sortBy, setSortBy] = useState<
    | "buying_price/asc"
    | "buying_price/desc"
    | "retail_price/asc"
    | "retail_price/desc"
    | "ua_name/desc"
    | "ua_name/asc"
    | ""
  >("");

  const { categoryId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const { data } = useProductsAllQuery({
    category_id: +(categoryId || "0"),
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  useEffect(() => {
    function chekLocalStorage() {
      if (
        !window.localStorage.getItem("searchProductsPage") ||
        window.localStorage.getItem("searchProductsPage") === ""
      ) {
        return null;
      } else {
        return true;
      }
    }

    setQueryParams(
      Object.assign(
        {
          limit:
            queryParams.get("limit") ||
            window.localStorage.getItem("limitProductsPage") ||
            "20",
          offset:
            queryParams.get("offset") ||
            window.localStorage.getItem("offsetProductsPage") ||
            "0",
        },
        chekLocalStorage() && {
          search:
            queryParams.get("search") ||
            window.localStorage.getItem("searchProductsPage") ||
            "",
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (data?.products.length === 0 && queryParams.get("offset") + "" !== "0") {
      setQueryParams(
        Object.assign(
          {
            limit:
              queryParams.get("limit") ||
              window.localStorage.getItem("limitProductsPage") ||
              "20",
            offset: "0",
          },
          queryParams.get("search") && {
            search:
              queryParams.get("search") ||
              window.localStorage.getItem("searchProductsPage") ||
              "",
          },
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem(
      "limitProductsPage",
      queryParams.get("limit") || "20",
    );
    window.localStorage.setItem(
      "offsetProductsPage",
      queryParams.get("offset") || "0",
    );
    window.localStorage.setItem(
      "searchProductsPage",
      queryParams.get("search") || "",
    );
  }, [queryParams]);

  return (
    <div className={cn(style["table"], "scroll")}>
      <Thead data={data?.products} sortBy={sortBy} setSortBy={setSortBy} />
      <Tbody
        data={data?.products}
        sortBy={sortBy.split("/")[0]}
        order={sortBy.split("/")[1]}
      />
    </div>
  );
}
