export function DownloadSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
    >
      <path
        d="M4.52941 6.29412V5.41176C4.52941 2.97522 6.50463 1 8.94118 1C11.3777 1 13.3529 2.97522 13.3529 5.41176V6.29412C15.3021 6.29412 16.8824 7.87432 16.8824 9.82353C16.8824 11.1299 16.1726 12.3015 15.1176 12.9118M4.52941 6.29412C2.58017 6.29412 1 7.87432 1 9.82353C1 11.1299 1.70976 12.3015 2.76471 12.9118M4.52941 6.29412C4.91134 6.29412 5.2791 6.35482 5.62358 6.46697M8.94118 8.05882V16M8.94118 8.05882L11.5882 10.7059M8.94118 8.05882L6.29412 10.7059"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
