import { BackButton, SubmitButton } from "@ui";
import style from "./TopPanel.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUnloadingCreateMutation,
  useUnloadingUpdateMutation,
} from "@RTKQuery/unloading/api";
import { useAppSelector } from "store/hooks";
import { useEffect } from "react";

export function TopPanel() {
  const { marketplaceId, unloadingId } = useParams();

  const [unloadingCreate, createStatus] = useUnloadingCreateMutation();
  const [unloadingUpdate, updateStatus] = useUnloadingUpdateMutation();

  const unloadingData = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject,
  );

  const navigate = useNavigate();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  useEffect(() => {
    if (createStatus.isSuccess) {
      navigate(
        crm + `/unloading/${marketplaceId}/link/${createStatus.data.id}`,
      );
    }

    const timer = setTimeout(() => {
      createStatus.reset();
      updateStatus.reset();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [
    createStatus.isSuccess,
    createStatus.isError,
    updateStatus.isSuccess,
    updateStatus.isError,
  ]);

  return (
    <div className={style["topPanel"]}>
      <div className={style["container"]}>
        <BackButton url={crm + "/unloading/" + marketplaceId} />
        <p className={style["currentPage"]}>Налаштування вигрузки</p>
      </div>
      <div className={style["button"]}>
        <SubmitButton
          className="max-sm:rounded-[6px] max-sm:text-[12px] "
          type="button"
          hFull
          disabled={createStatus.isLoading || updateStatus.isLoading}
          isUninitialized={
            createStatus.isUninitialized && updateStatus.isUninitialized
          }
          isLoading={createStatus.isLoading || updateStatus.isLoading}
          isSuccess={createStatus.isSuccess || updateStatus.isSuccess}
          isError={createStatus.isError || updateStatus.isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={""}
          onClick={() => {
            if (!unloadingId) {
              unloadingCreate(unloadingData);
            } else {
              unloadingUpdate(unloadingData);
            }
          }}
        />
      </div>
    </div>
  );
}
