import {
  productsTemplate_delete_addData,
  productsTemplate_delete_deleteData,
} from "@global-state/temporal-data/slice";
import ProductsTemplate from "@interfaces/products-template";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function TemplateCheckbox({
  template,
}: {
  template: Pick<ProductsTemplate, "id" | "name">;
}) {
  const delete_template_ids = useAppSelector(
    (state) => state.temporalData.products_template.delete,
  )
    .filter((item) => item.tag === "multiple")
    .map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={delete_template_ids.includes(template.id)}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            productsTemplate_delete_addData([
              {
                id: template.id,
                tag: "multiple",
              },
            ]),
          );
        } else {
          dispatch(
            productsTemplate_delete_deleteData({
              id: template.id,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
