import { ReferenceBookValue } from "@interfaces/refence-book";
import style from "./ValueButtons.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import {
  referenceBook_deleteValues_addData,
  referenceBook_editValues_addData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function ValueButtons({ value }: { value: ReferenceBookValue }) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <Button
        type="delete"
        onClick={() => {
          dispatch(
            referenceBook_deleteValues_addData([
              {
                id: value.id,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              name: "DeleteBookValueModal",
              mode: "single",
            }),
          );
        }}
      />
      <Button
        type="edit"
        onClick={() => {
          dispatch(
            referenceBook_editValues_addData([
              {
                id: value.id,
                name: value.name,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              name: "EditBookValueModal",
              mode: "single",
            }),
          );
        }}
      />
    </div>
  );
}
