import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import style from "./ForgotPasswordCRMForm.module.scss";
import { axiosLog } from "@api";
import { Input, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
});

export function ForgotPasswordCRMForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const crm = window.localStorage.getItem("subdomain");

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog(`https://${crm}.api.levelup.com.ua`)
          .post(
            "/dropers/auth/forgot-password",
            {
              email: values.email,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response.data.ok) {
              setSubmitingStatus("success");

              setTimeout(() => {
                setSubmitingStatus("idle");
              }, 5000);
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText: err?.response?.data?.error || "Error",
            });
            console.error(err);
          });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input
            formikName="email"
            label="E-mail"
            inputType="email"
            placeholder="youremail@gmail.com"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Скинути"
            successText="Успішно"
            errorText="Помилка"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
