import { createSlice } from "@reduxjs/toolkit";
import { UnloadingPageData } from "./interface";
import { SliceAction } from "store/store";
import { FileFormat, FormulaMarkup, ProductHoroshop } from "@interfaces/unloading";
import Category from "@interfaces/category";

const initialState: UnloadingPageData = {
    unloadingInfoObject: {
        name: "",
        categories: [],
        file_format: FileFormat.CSV,
        formula_markup: [
            {
                from_price: 0,
                to_price: 0,
                mark_up: 0
            },
            {
                from_price: 0,
                to_price: null,
                mark_up: 0
            }
        ]
    },
    selectProduct: []
}

const unloadingPageSlice = createSlice({
    initialState,
    name: "unloadingPage",
    reducers: {
        setAuthenticUnloadingObject: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]>) => {
            state.unloadingInfoObject = action.payload;
        },
        switchUseMaxDropPrice: (state) => {
            state.unloadingInfoObject.use_max_drop_price = !state.unloadingInfoObject.use_max_drop_price;
        },
        switchUseRetailPrice: (state) => {
            state.unloadingInfoObject.use_retail_price = !state.unloadingInfoObject.use_retail_price;
        },
        selectFileFormat: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["file_format"]>) => {
            state.unloadingInfoObject.file_format = action.payload;
        },
        switchUseFixedMarkup: (state) => {
            state.unloadingInfoObject.use_fixed_markup = !state.unloadingInfoObject.use_fixed_markup;
            state.unloadingInfoObject.use_percent_markup = false;
        },
        setFixedMarkup: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["fixed_markup"]>) => {
            state.unloadingInfoObject.fixed_markup = action.payload;
        },
        switchUsePercentMarkup: (state) => {
            state.unloadingInfoObject.use_percent_markup = !state.unloadingInfoObject.use_percent_markup;
            state.unloadingInfoObject.use_fixed_markup = false;
        },
        setPercentMarkup: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["percent_markup"]>) => {
            state.unloadingInfoObject.percent_markup = action.payload;
        },

        switchUseFormulaMarkup: (state) => {
            state.unloadingInfoObject.use_formula_markup = !state.unloadingInfoObject.use_formula_markup;
        },
        setFormulaMarkupToPrice: (state, action: SliceAction<{
            value: FormulaMarkup["to_price"],
            indexRange: number
        }>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload.indexRange].to_price = action.payload.value;
                if (state.unloadingInfoObject.formula_markup[action.payload.indexRange + 1] && action.payload.value) {
                    state.unloadingInfoObject.formula_markup[action.payload.indexRange + 1].from_price = action.payload.value;
                }
            }
        },
        setFormulaMarkupMarkUp: (state, action: SliceAction<{
            value: FormulaMarkup["mark_up"],
            indexRange: number
        }>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload.indexRange].mark_up = action.payload.value;
                
            }
        },
        addFormulaMarkupRange: (state) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[state.unloadingInfoObject.formula_markup?.length - 1].to_price = 0;
                state.unloadingInfoObject.formula_markup.push({
                    from_price: 0,
                    to_price: null,
                    mark_up: 0
                })
            }
        },
        deleteFormulaMarkupRange: (state, action: SliceAction<number>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload + 1].from_price = state.unloadingInfoObject.formula_markup[action.payload - 1].to_price || 0;
                state.unloadingInfoObject.formula_markup.splice(action.payload, 1);
            }
        },
        setCategories: (state, action: SliceAction<Category["id"][]>) => {
            state.unloadingInfoObject.categories = action.payload;
        },
        clearCategories: (state) => {
            state.unloadingInfoObject.categories = [];
        },
        addCategories: (state, action: SliceAction<Category["id"]>) => {
            state.unloadingInfoObject.categories.push(action.payload);
        },
        removeCategories: (state, action: SliceAction<Category["id"]>) => {
            state.unloadingInfoObject.categories = state.unloadingInfoObject.categories.filter(item => item !== action.payload);
        },
        setUnloadingName: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["name"]>) => {
            state.unloadingInfoObject.name = action.payload;
        },
        setSelectProduct: (state, action: SliceAction<ProductHoroshop["id"][]>) => {
            state.selectProduct = action.payload;
        },
        clearSelectProduct: (state) => {
            state.selectProduct = [];
        },
        addSelectProduct: (state, action: SliceAction<ProductHoroshop["id"]>) => {
            state.selectProduct.push(action.payload);
        },
        removeSelectProduct: (state, action: SliceAction<ProductHoroshop["id"]>) => {
            state.selectProduct = state.selectProduct.filter(item => item !== action.payload);
        },
        clearAllUnloadingData: (state) => {
            state.unloadingInfoObject = {
                name: "",
                categories: [],
                file_format: FileFormat.CSV,
                formula_markup: [
                    {
                        from_price: 0,
                        to_price: 0,
                        mark_up: 0
                    },
                    {
                        from_price: 0,
                        to_price: null,
                        mark_up: 0
                    }
                ]
            }
            state.selectProduct = []
        }
    }
})

const { reducer, actions } = unloadingPageSlice;

export default reducer;

export const {
    setAuthenticUnloadingObject,
    switchUseMaxDropPrice,
    switchUseRetailPrice,
    selectFileFormat,
    switchUseFixedMarkup,
    switchUsePercentMarkup,
    setFixedMarkup,
    setPercentMarkup,
    switchUseFormulaMarkup,
    setFormulaMarkupToPrice,
    setFormulaMarkupMarkUp,
    addFormulaMarkupRange,
    deleteFormulaMarkupRange,
    setCategories,
    clearCategories,
    addCategories,
    removeCategories,
    setUnloadingName,
    addSelectProduct,
    removeSelectProduct,
    setSelectProduct,
    clearSelectProduct,
    clearAllUnloadingData
} = actions;