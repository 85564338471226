import { FaUser } from "react-icons/fa6";

import style from "./ProfileButton.module.scss";
import { LogoutButton } from "..";
import { useAppSelector } from "store/hooks";

export function ProfileButton() {
  const user = useAppSelector((state) => state.CRMUser.user);
  const roleName = useAppSelector((state) => state.CRMUser.role.role_name);

  return (
    <div className={style["userInfo"]}>
      <div className={style["menuAvatar"]}>
        <FaUser className="h-full w-full fill-[#bcc7db]" />
      </div>
      <div className={style["hoverHelper"]}>
        <div className={style["box"]}>
          <div className={style["role"]}>{roleName.toLowerCase()}</div>
          <div className="px-[25px] pb-[25px]">
            <div className={style["info"]}>
              <div className={style["avatar"]}>
                <FaUser className="h-full w-full fill-[#bcc7db]" />
              </div>
              <div className={style["text"]}>
                <p className={style["userName"]}>
                  {user.last_name} {user.first_name}
                </p>
                <p title={user.email} className={style["email"]}>
                  {user.email}
                </p>
              </div>
            </div>
            <LogoutButton />
          </div>
        </div>
      </div>
      <div className={style["arrow"]}></div>
    </div>
  );
}
