import { generateCombinations } from "@global-state/product/slice";
import { api } from "..";
import {
  ProductChildren,
  ProductExtended,
  ProductVariations,
} from "../../interfaces/product";

interface ProductUpdateVariations {
  product: {
    id: ProductExtended["id"];
    variations: ProductExtended["variations"];
  };
  children: {
    id?: ProductExtended["id"];
    sku?: ProductExtended["sku"];
    variations: ProductExtended["variations"];
  }[];
}

const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    productCreate: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      payload: Partial<
        ProductChildren<
          Omit<
            ProductExtended,
            "id" | "parent_id" | "pictures" | "characteristics"
          > & { characteristics: Record<string, number[]> }
        >
      >;
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();

        product.pictures.positions.forEach((fileId) =>
          formData.append("pictures", product.pictures.files[fileId]),
        );
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: "products",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
    }),
    productUpdate: build.mutation<{ product: ProductExtended }, {
      payload: Omit<
        ProductExtended,
        "pictures" | "variations" | "characteristics"
      > & { characteristics: Record<string, number[]> };
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();

        product.pictures.positions.forEach((fileId) => {
          if (product.pictures.files[fileId].name) {
            formData.append("pictures", product.pictures.files[fileId]);
          } else {
            formData.append(
              "pictures",
              product.pictures.files[fileId],
              `${fileId}.json`,
            );
          }
        });
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: `products/${product.payload.id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(
        { payload: { id, parent_id } },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          if (!parent_id) {
            dispatch(
              api.util.updateQueryData("productsOne", id, (draft) => {
                draft.product = updatedPost.product;
              }),
            );
          } else {
            dispatch(
              api.util.updateQueryData("productsOne", parent_id, (draft) => {
                const idMutableItem = draft.children.findIndex(
                  (item) => item.id === updatedPost.product.id,
                );

                draft.children[idMutableItem] = updatedPost.product;
              }),
            );
          }
        } catch { }
      },
    }),
    productDelete: build.mutation<void, | ProductExtended["id"][] | {
      parentId: ProductExtended["id"];
      childId: ProductExtended["id"];
    }>({
      query: (data) => {
        let listItem = "";

        if (Array.isArray(data)) {
          listItem = data.map((id) => `items=${id}`).join("&");
        } else {
          listItem = `items=${data.childId}`;
        }

        return {
          url: `products?${listItem}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (!Array.isArray(data)) {
          try {
            await queryFulfilled;

            dispatch(
              api.util.updateQueryData(
                "productsOne",
                data.parentId,
                (draft) => {
                  draft.children = draft.children.filter(
                    (item) => item.id !== data.childId,
                  );
                },
              ),
            );
          } catch { }
        }
      },
    }),
    productDeleteVariations: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      parentId: ProductExtended["id"];
      childIds: ProductExtended["id"][];
    }>({
      query: ({ childIds, parentId }) => {
        let listItem = childIds.map((id) => `items=${id}`).join("&");

        return {
          url: `products/variations?${listItem}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (!Array.isArray(data)) {
          try {
            const newData = await queryFulfilled;

            dispatch(
              api.util.upsertQueryData(
                "productsOne",
                data.parentId,
                newData.data,
              ),
            );
          } catch { }
        }
      },
    }),
    productUpdateVariations: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      product: ProductChildren<ProductExtended>;
      variations: ProductVariations["variations"];
    }>({
      query: ({ product, variations }) => {
        let combinations = generateCombinations(variations);
        let combinationsConcatStr = combinations.map((combination) =>
          combination.reduce<string>((concatStr, item) => {
            return concatStr + "|" + item.name + ":" + item.value;
          }, ""),
        );

        const newVariations: ProductUpdateVariations = {
          product: {
            id: product.product.id,
            variations: (() => {
              const variationsConcatStr =
                (product.product.variations || []).reduce<string>((concatStr, item) => {
                  return concatStr + "|" + item.name + ":" + item.value;
                }, "");

              const combinationIndex = combinationsConcatStr.findIndex(
                (combination) => combination.includes(variationsConcatStr),
              );

              const combination = combinations.splice(combinationIndex, 1)[0];

              combinationsConcatStr.splice(combinationIndex, 1);

              return combination.slice(
                variationsConcatStr.split("|").length - 1,
              );
            })(),
          },
          children: [
            ...product.children.map((child) => {
              const variationsConcatStr = child.variations.reduce<string>(
                (concatStr, item) => {
                  return concatStr + "|" + item.name + ":" + item.value;
                },
                "",
              );

              const combinationIndex = combinationsConcatStr.findIndex(
                (combination) => combination.includes(variationsConcatStr),
              );

              const combination = combinations.splice(combinationIndex, 1)[0];

              combinationsConcatStr.splice(combinationIndex, 1);

              return {
                id: child.id,
                variations: combination.slice(
                  variationsConcatStr.split("|").length - 1,
                ),
              };
            }),
            ...combinations.map((combination) => ({
              sku:
                product.product.sku.split("-")[0] +
                "-" +
                combination.map((elem) => elem.value).join("-"),
              variations: combination,
            })),
          ],
        };

        return {
          url: `products/variations`,
          method: "POST",
          body: newVariations,
        };
      },
      async onQueryStarted({ product }, { dispatch, queryFulfilled }) {
        try {
          const { data: newData } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsOne",
              product.product.id,
              newData,
            ),
          );
        } catch { }
      },
    }),
    productAddPictures: build.mutation<void, {
      payload: {
        products: ProductExtended["id"][];
        parent_id: ProductExtended["id"];
      };
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();
        
        product.pictures.positions.forEach((fileId) =>
          formData.append("pictures", product.pictures.files[fileId]),
        );
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: "products/multi/pictures",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useProductsAllQuery,
  useProductsOneQuery,
  useProductCreateMutation,
  useProductUpdateMutation,
  useProductDeleteMutation,
  useProductDeleteVariationsMutation,
  useProductUpdateVariationsMutation,
  useProductAddPicturesMutation
} = productsApi;
