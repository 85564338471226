import {
  MarketplacesTabs,
  UnloadingsAddButton,
  UnloadingsContentWrapper,
  UnloadingsList,
} from "./components";

export function UnloadingsContent() {
  return (
    <UnloadingsContentWrapper>
      <>
        <MarketplacesTabs />
        <UnloadingsList />
        <UnloadingsAddButton />
      </>
    </UnloadingsContentWrapper>
  );
}
