import { createSlice } from "@reduxjs/toolkit";
import Product, { NewVariation, Picture } from "./interface";
import { SliceAction } from "../../store/store";
import { ProductExtended } from "@interfaces/product";

const initialState: Product = {
  authenticProductObject: undefined,
  basicInfo: {
    data: null,
    isValid: false,
  },
  pictures: [],
  picturesModal: [],
  characteristics: {},
  combinationsSelectedVariationValues: [[]],
  listsVariationValues: [],
};

const productPageSlice = createSlice({
  initialState,
  name: "productPage",
  reducers: {
    setAuthenticProductObject: (state, action: SliceAction<Product["authenticProductObject"]>) => {
      state.authenticProductObject = action.payload;
    },

    setIsValidBasicInfo: (state, action: SliceAction<boolean>) => {
      state.basicInfo.isValid = action.payload;
    },
    setDataBasicInfo: (
      state,
      action: SliceAction<Product["basicInfo"]["data"]>,
    ) => {
      state.basicInfo.data = action.payload;
    },
    clearDataBasicInfo: (state) => {
      state.basicInfo.data = null;
    },

    addPictures: (state, action: SliceAction<Product["pictures"]>) => {
      state.pictures = [...state.pictures, ...action.payload];
    },
    setPicures: (state, action: SliceAction<Product["pictures"]>) => {
      state.pictures = action.payload;
    },
    removePictures: (state, action: SliceAction<Picture["id"]>) => {
      state.pictures = state.pictures.filter(
        (item) => item.id !== action.payload,
      );
    },

    addPicturesModal: (state, action: SliceAction<Product["picturesModal"]>) => {
      state.picturesModal = [...state.picturesModal, ...action.payload];
    },
    setPicuressModal: (state, action: SliceAction<Product["picturesModal"]>) => {
      state.picturesModal = action.payload;
    },
    removePicturesModal: (state, action: SliceAction<Picture["id"]>) => {
      state.picturesModal = state.picturesModal.filter(
        (item) => item.id !== action.payload,
      );
    },

    setCombinationsSelected: (state, action: SliceAction<NewVariation[]>) => {
      state.combinationsSelectedVariationValues = generateCombinations(action.payload);
    },
    setCharacteristics: (
      state,
      action: SliceAction<Product["characteristics"]>,
    ) => {
      state.characteristics = action.payload;
    },

    setVariations: (state, action: SliceAction<Product["listsVariationValues"]>) => {
      state.listsVariationValues = action.payload;
    },

    clearAllProductData: (state) => {
      state.authenticProductObject = undefined;
      state.basicInfo = {
        data: null,
        isValid: false,
      };
      state.pictures = [];
      state.picturesModal = [];
      state.combinationsSelectedVariationValues = [[]];
      state.characteristics = {};
      state.listsVariationValues = [];
    },
  },
});

const { reducer, actions } = productPageSlice;

export default reducer;

export const {
  setAuthenticProductObject,

  setIsValidBasicInfo,
  setDataBasicInfo,
  clearDataBasicInfo,

  addPictures,
  setPicures,
  removePictures,

  addPicturesModal,
  setPicuressModal,
  removePicturesModal,

  setCombinationsSelected,
  setCharacteristics,

  setVariations,

  clearAllProductData,
} = actions;

export function generateCombinations(data: NewVariation[]) {
  function combine(
    currentCombination: ProductExtended["variations"],
    remaining: NewVariation[],
  ): ProductExtended["variations"][] {
    if (remaining.length === 0) {
      return [currentCombination];
    }

    const next = remaining[0];
    const rest = remaining.slice(1);
    const combinations: ProductExtended["variations"][] = [];

    next.values.forEach((value) => {
      const newCombination = currentCombination.concat({
        name: next.name,
        value: value,
      });

      combinations.push(...combine(newCombination, rest));
    });

    return combinations;
  }

  return combine([], data);
}