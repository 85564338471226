import { useContext } from "react";
import { variationsContext } from "../../Core";
import style from "./ListVariationsItem.module.scss";
import { SelectableParameter } from "@interfaces/product";
import { Checkbox } from "@ui";
import { ListVariationsValues } from "../ListVariationsValues";

export function ListVariationsItem({ data }: { data: SelectableParameter }) {
  const context = useContext(variationsContext);

  return (
    <>
      <div className={style["itemName"]}>
        <Checkbox
          alignLabel="left"
          label={<p className={style["customLabel"]}>{data.name}</p>}
          checked={data.selected}
          onChange={(e) => {
            context?.switchChekedParameter(data.id, e.target.checked);
          }}
        />
      </div>
      <ListVariationsValues data={data.values} parentId={data.id} />
    </>
  );
}
