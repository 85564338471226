import { Form, Formik } from "formik";
import * as Yup from "yup";

import style from "./RegisterForm.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosLog } from "@api";
import { BasicInputProps, Input, Radio, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Ваше ім'я та прізвище - обов'язкове поле"),
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
  subDomainName: Yup.string()
    .matches(/^[a-z0-9-]+$/, "Тільки малі латинські літери, цифри та дефіс")
    .min(4, "Не менше 4 символів")
    .max(16, "Не більше 16 символів")
    .required("Назва домену - обов'язкове поле"),
});

export function RegisterForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        subDomainName: "",
        accountType: "droper",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog("https://account-api.levelup.com.ua")
          .post("/auth/register", {
            first_name: values.fullName.split(" ")[0],
            last_name: values.fullName.split(" ")[1],
            email: values.email,
            country: (navigator.language || navigator.languages[0]).split(
              "-",
            )[1],
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            lang: (navigator.language || navigator.languages[0]).split("-")[0],
            subdomain: values.subDomainName,
            account_type: values.accountType,
          })
          .then((response) => {
            if (response.data.ok) {
              window.localStorage.setItem(
                "service_token",
                response.data.service_token,
              );
              setSubmitingStatus("success");
              navigate(
                process.env.NODE_ENV === "development"
                  ? "/account/auth/verify"
                  : "/auth/verify",
              );
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
            console.error(err);

            setTimeout(() => {
              setSubmitingStatus("idle");
            }, 10000);
          });
      }}
    >
      {(props) => (
        <Form>
          <div className={style["inputsBox"]}>
            <Input<BasicInputProps>
              formikName="fullName"
              label="Ваше ім'я та прізвище"
              inputType="text"
            />
            <Input<BasicInputProps>
              formikName="email"
              label="E-mail"
              inputType="email"
              placeholder="Приклад: yourmail@gmai.com"
            />
            <Input<BasicInputProps>
              formikName="subDomainName"
              label="Назва домену"
              inputType="text"
            />
            <div className={style["radioBox"]}>
              <Radio
                checked={props.values.accountType === "droper"}
                onChange={() => {
                  props.setFieldValue("accountType", "droper");
                }}
                label="Дропшипер"
              />
              <Radio
                checked={props.values.accountType === "supplier"}
                onChange={() => {
                  props.setFieldValue("accountType", "supplier");
                }}
                label="Постачальник"
              />
            </div>
          </div>
          <div className={style["submitButtonBox"]}>
            <SubmitButton
              type="submit"
              disabled={submitingStatus === "load"}
              isUninitialized={submitingStatus === "idle"}
              isLoading={submitingStatus === "load"}
              isSuccess={submitingStatus === "success"}
              isError={typeof submitingStatus === "object"}
              uninitializedText="Зареєструватись"
              successText="Успішно"
              errorText="Не вдалось зареєструватись"
              errorMesage={
                typeof submitingStatus === "object"
                  ? submitingStatus.errorText
                  : ""
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
