import { RowProduct } from "./components";
import cn from "classnames";

import style from "./Tbody.module.scss";
import { ProductHoroshop } from "@interfaces/unloading";

export function Tbody({
  data,
  sortBy,
  order,
}: {
  data: ProductHoroshop[] | undefined;
  sortBy: string;
  order: string;
}) {
  return (
    <div className={cn(style["tbody"], "scroll")}>
      {data
        ?.slice()
        .sort((a, b) => {
          if (sortBy === "price") {
            return sort(a.buying_price, b.buying_price, order);
          } else if (sortBy === "ua_name") {
            return sort(a.ua_name, b.ua_name, order);
          } else if (sortBy === "category") {
            return sort(
              a.categories?.join(", ") || "",
              b.categories?.join(", ") || "",
              order,
            );
          } else {
            return 0;
          }
        })
        .map((item) => <RowProduct key={item.id} data={item} />)}
    </div>
  );
}

function sort<T>(a: T, b: T, order: string) {
  console.log(a);
  console.log(b);

  if (typeof a === "string" && typeof b === "string") {
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === "number" && typeof b === "number") {
    return order === "asc" ? a - b : b - a;
  } else {
    return 0;
  }
}
