import { useParams } from "react-router-dom";
import style from "./UnloadingOnePage.module.scss";
import { useUnloadingOneQuery } from "@RTKQuery/unloading/api";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import {
  clearAllUnloadingData,
  setAuthenticUnloadingObject,
} from "@global-state/unloading/slice";
import {
  LongSettings,
  ProductsCategories,
  ProductsTable,
  QuickSettings,
  TopPanel,
} from "components/catalog/unloading";
import cn from "classnames";
import { SetHoroshopCategoriesModal } from "@modals";

export function UnloadingOnePage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllUnloadingData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={cn(style["scrollContainer"], "scroll")}>
        <div className={style["flexContainer"]}>
          <QuickSettings />
          <LongSettings />
        </div>
        <div className={style["flexContainer"]}>
          <ProductsCategories />
          <ProductsTable />
        </div>
      </div>
      <SetHoroshopCategoriesModal />
    </main>
  );
}

export function CreateUnloading({ children }: { children: JSX.Element }) {
  return children;
}

export function EditUnloading({ children }: { children: JSX.Element }) {
  const { unloadingId } = useParams();
  const { data } = useUnloadingOneQuery(+(unloadingId || "-1"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setAuthenticUnloadingObject(data));
    }
  }, [data]);

  if (data) {
    return children;
  } else {
    return null;
  }
}
