import { useParams } from "react-router-dom";
import { AllVariationCheckbox, ItemVariation } from "./components";
import cn from "classnames";
import style from "./ListVariations.module.scss";
import { useProductsTemplateOneQuery } from "@RTKQuery/products-template/api";

export function ListVariations() {
  const { id: idTemplate } = useParams();

  const { data } = useProductsTemplateOneQuery(Number(idTemplate));

  return (
    <div className="h-full pb-[30px] max-sm:pb-0">
      <div className={style["listHeader"]}>
        <AllVariationCheckbox variations={data?.variations} />
        <div className={style["label"]}>Варіації</div>
      </div>
      <div className={cn(style["limit"], "scroll")}>
        <div className={style["listBody"]}>
          {data?.variations.map((item) => (
            <ItemVariation key={item.id} variation={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
