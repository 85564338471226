import { useParams } from "react-router-dom";
import { AllCharacteristicsCheckbox, ItemCharacteristic } from "./components";
import cn from "classnames";

import style from "./ListCharacteristics.module.scss";
import { useProductsTemplateOneQuery } from "@RTKQuery/products-template/api";

export function ListCharacteristics() {
  const { id: idTemplate } = useParams();

  const { data } = useProductsTemplateOneQuery(Number(idTemplate));

  return (
    <div className="h-full pb-[30px] max-sm:pb-0">
      <div className={style["listHeader"]}>
        <AllCharacteristicsCheckbox characteristics={data?.characteristics} />
        <div className={style["label"]}>Характеристики</div>
      </div>
      <div className={cn(style["limit"], "scroll")}>
        <div className={style["listBody"]}>
          {data?.characteristics.map((item) => (
            <ItemCharacteristic key={item.id} characteristic={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
