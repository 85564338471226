export function ProductsTemplateSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M14.3346 10.3802H18.1445V16.0951C18.1445 17.1472 17.2917 18 16.2396 18M14.3346 10.3802V16.0951C14.3346 17.1472 15.1875 18 16.2396 18M14.3346 10.3802V3.48152C14.3346 2.61916 14.3346 2.18797 14.1535 1.93793C13.9955 1.71981 13.7525 1.5788 13.4847 1.5498C13.1777 1.51657 12.8033 1.7305 12.0546 2.15835L11.7571 2.32836C11.4815 2.48584 11.3437 2.56458 11.1974 2.59541C11.0678 2.62269 10.9341 2.62269 10.8046 2.59541C10.6583 2.56458 10.5205 2.48584 10.2449 2.32836L8.42339 1.28752C8.14784 1.13004 8.01002 1.0513 7.86372 1.02047C7.73418 0.993178 7.60044 0.993178 7.47095 1.02047C7.32461 1.0513 7.18682 1.13004 6.91123 1.28752L5.08975 2.32836C4.81416 2.48584 4.67637 2.56458 4.53003 2.59541C4.40054 2.62269 4.26678 2.62269 4.13729 2.59541C3.99095 2.56458 3.85316 2.48584 3.57757 2.32836L3.28005 2.15835C2.53131 1.7305 2.15694 1.51657 1.84996 1.5498C1.58218 1.5788 1.33919 1.71981 1.18116 1.93793C1 2.18797 1 2.61916 1 3.48152V13.4281C1 15.0285 1 15.8286 1.31144 16.4398C1.58539 16.9775 2.02252 17.4146 2.56018 17.6885C3.17141 18 3.97157 18 5.57188 18H16.2396M4.8099 10.3802H6.71485M4.8099 6.57031H10.5247M4.8099 14.1901H6.71485M10.5247 14.1901H10.5343M10.5247 10.3802H10.5343"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
