export function CrossSvg({ className }: { className: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.55005 0.5L0.550049 5.5M0.550055 0.5L5.55005 5.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
