import cn from "classnames";
import style from "./Balance.module.scss";
import { useBalanceQuery } from "@RTKQuery/balance/api";

export function Balance() {
  const { data } = useBalanceQuery();

  return (
    <div className={style["conteiner"]}>
      <p className={cn(style["balance"])}>$ {data?.balance}</p>
    </div>
  );
}
