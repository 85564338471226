import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActionsValues.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/modal-state/slice";

export function MultipleActionsValues() {
  const delete_values = useAppSelector(
    (state) => state.temporalData.reference_book.delete_values,
  );
  const edit_values = useAppSelector(
    (state) => state.temporalData.reference_book.edit_values,
  );

  const dispatch = useAppDispatch();

  if (
    delete_values.some((item) => item.tag === "multiple") ||
    edit_values.some((item) => item.tag === "multiple")
  ) {
    return (
      <div className={style["multipleActions"]}>
        <div className={style["button"]}>
          <Button
            type="delete"
            size="big"
            text="Видалити"
            onClick={() => {
              dispatch(
                showModal({
                  name: "DeleteBookValueModal",
                  mode: "multiple",
                }),
              );
            }}
          />
        </div>
        <div className={style["button"]}>
          <Button
            type="edit"
            size="big"
            variant="outlined"
            text="Редагувати"
            onClick={() => {
              dispatch(
                showModal({
                  name: "EditBookValueModal",
                  mode: "multiple",
                }),
              );
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
