export function CatalogSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
    >
      <rect
        x="1.5333"
        y="1.4"
        width="5"
        height="15.2"
        rx="2.1"
        strokeWidth="1.8"
      />
      <rect
        x="9.46665"
        y="1.4"
        width="5"
        height="5"
        rx="2.1"
        strokeWidth="1.8"
      />
      <rect
        x="9.46665"
        y="9.33335"
        width="5"
        height="7.26667"
        rx="2.1"
        strokeWidth="1.8"
      />
    </svg>
  );
}
