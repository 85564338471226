import { ProductExtended } from "@interfaces/product";
import {
  useProductCreateMutation,
  useProductDeleteMutation,
  useProductUpdateMutation,
} from "@RTKQuery/products/api";
import { Button } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect } from "react";
import { useBlocker, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

export function BlockerModal({
  oldDataActiveProduct,
}: {
  oldDataActiveProduct?: ProductExtended;
}) {
  const { categoryId, variationId } = useParams();

  const [productCreate, createStatus] = useProductCreateMutation({
    fixedCacheKey: "product-create",
  });

  const [productUpdate, updateStatus] = useProductUpdateMutation({
    fixedCacheKey: "product-edit",
  });

  const [deleteProducts, deleteStatus] = useProductDeleteMutation({
    fixedCacheKey: "delete-product-variations",
  });

  const product = useAppSelector((state) => state.productPage);

  const blocker = useBlocker(() => cheсkChanges());

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadEventHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadEventHandler);
    };
  }, [product]);

  return (
    <CRMModalLayout
      isShow={blocker.state === "blocked"}
      hiddenModal={() => {
        if (blocker.state === "blocked") {
          blocker.reset();
        }
      }}
      title="Увага!"
      description={
        <>
          Зміни не збережені. <br /> Ви впевнені, що хочете перейти на іншу
          сторінку?
        </>
      }
    >
      <>
        <div className="flex h-[43px] gap-[20px]">
          <Button
            onClick={() => {
              if (blocker.state === "blocked") {
                blocker.reset();
              }
            }}
            size="big"
            text="Скасувати"
          />
          <Button
            onClick={() => {
              if (blocker.state === "blocked") {
                blocker.proceed();
              }
            }}
            size="big"
            text="Продовжити"
            variant="outlined"
          />
        </div>
      </>
    </CRMModalLayout>
  );

  function beforeUnloadEventHandler(e: BeforeUnloadEvent) {
    if (cheсkChanges()) {
      e.preventDefault();
    }
  }

  function cheсkChanges() {
    if (
      createStatus.isLoading ||
      updateStatus.isLoading ||
      deleteStatus.isSuccess
    ) {
      return false;
    } else {
      return (
        (() => {
          if (cheсkBasicInfo()) {
            console.log("Базова інформація");
            return true;
          } else {
            return false;
          }
        })() ||
        (() => {
          if (cheсkPictures()) {
            console.log("Картинки");
            return true;
          } else {
            return false;
          }
        })() ||
        (() => {
          if (cheсkCharacteristics()) {
            console.log("Характеристики");
            return true;
          } else {
            return false;
          }
        })() ||
        (() => {
          if (checkVariations()) {
            console.log("Варіації");
            return true;
          } else {
            return false;
          }
        })()
      );
    }

    function cheсkBasicInfo() {
      const { data } = product.basicInfo;

      if (data) {
        return (
          (oldDataActiveProduct?.sku || "") !== data.sku ||
          (oldDataActiveProduct?.ua_name || "") !== data.ua_name ||
          (oldDataActiveProduct?.ru_name || "") !== data.ru_name ||
          (oldDataActiveProduct?.buying_price || 0) !== data.buying_price ||
          (oldDataActiveProduct?.old_price || 0) !== data.old_price ||
          (oldDataActiveProduct?.retail_price || 0) !== data.retail_price ||
          (oldDataActiveProduct?.discount || 0) !== data.discount ||
          (oldDataActiveProduct?.ua_description || "") !==
            data.ua_description ||
          (oldDataActiveProduct?.ru_description || "") !==
            data.ru_description ||
          (oldDataActiveProduct?.category_id || Number(categoryId || "-1")) !==
            Number(data.category_id)
        );
      } else {
        return false;
      }
    }

    function cheсkPictures() {
      if (oldDataActiveProduct) {
        if (product.pictures.length !== oldDataActiveProduct.pictures.length) {
          return true;
        } else {
          return product.pictures.some(
            (item, index) =>
              item.id !== String(oldDataActiveProduct.pictures[index].id),
          );
        }
      } else {
        return product.pictures.length > 0;
      }
    }

    function cheсkCharacteristics() {
      if (variationId) return false;

      if (!oldDataActiveProduct) {
        return Object.keys(product.characteristics).length > 0;
      } else {
        if (oldDataActiveProduct.characteristics) {
          return oldDataActiveProduct.characteristics.some((char) => {
            return char.values.some((value) => {
              if (value.selected) {
                return !product.characteristics[char.id].includes(value.id);
              } else {
                return false;
              }
            });
          });
        } else {
          return false;
        }
      }
    }

    function checkVariations() {
      if (!oldDataActiveProduct) {
        return product.combinationsSelectedVariationValues[0].length > 0;
      } else {
        return false;
      }
    }
  }
}
