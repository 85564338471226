import { ProductExtended } from "@interfaces/product";
import {
  BasicInformationWrapper,
  CharacteristicsSettings,
  VariationsSettings,
} from "./components";
import { BasicForm } from "@forms";

export function BasicInformation({
  sourceProductInformation,
  basicFormRef,
}: {
  sourceProductInformation?: ProductExtended;
  basicFormRef: React.RefObject<HTMLButtonElement>;
}) {
  return (
    <BasicInformationWrapper>
      <>
        <BasicForm
          product={sourceProductInformation}
          submitRef={basicFormRef}
        />
        <CharacteristicsSettings />
        <VariationsSettings />
      </>
    </BasicInformationWrapper>
  );
}
