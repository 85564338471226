import { MdInfoOutline } from "react-icons/md";
import { useAppSelector } from "store/hooks";
import { ProductVariationsItem } from "../ProductVariationsItem";
import cn from "classnames";
import style from "./ProductVariationsList.module.scss";
import { AllVariationsCheckbox } from "./components";

export function ProductVariationsList() {
  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );
  const combinationsSelectedVariationValues = useAppSelector(
    (state) => state.productPage.combinationsSelectedVariationValues,
  );

  if (
    combinationsSelectedVariationValues[0].length > 0 ||
    authenticProductObject?.product.variations
  ) {
    return (
      <div className={cn(style["scrollContainer"], "scroll")}>
        <div className={style["gridTable"]}>
          <>
            <div className={cn(style["headCell"], "!ml-[18px] !pl-0")}>
              {!authenticProductObject ? (
                <div
                  className={style["blocker"]}
                  title="Спочатку збережіть зміни"
                />
              ) : null}
              <AllVariationsCheckbox
                variationList={authenticProductObject?.children}
              />
            </div>
            <div className={style["headCell"]}>
              <p>Артикул</p>
              {combinationsSelectedVariationValues[0].length > 0 && (
                <div className={style["hint"]}>
                  <MdInfoOutline className="h-[16px] w-[16px]" />
                  <div className={style["descr"]}>
                    Артикули згенеруються автоматично після збереження
                  </div>
                </div>
              )}
            </div>
            <div className={style["headCell"]}>Властивості</div>
            <div className={style["headCell"]}></div>
          </>
          {authenticProductObject && (
            <>
              <ProductVariationsItem
                key={0}
                parent
                data={{
                  id: authenticProductObject.product.id,
                  sku: authenticProductObject.product.sku,
                  values: authenticProductObject.product.variations,
                }}
              />
              {authenticProductObject.children.map((item, i) => (
                <ProductVariationsItem
                  key={i + 1}
                  data={{
                    id: item.id,
                    sku: item.sku,
                    values: item.variations,
                  }}
                />
              ))}
            </>
          )}
          {combinationsSelectedVariationValues[0].length > 0 &&
            combinationsSelectedVariationValues.map((item, i) => (
              <ProductVariationsItem
                key={i}
                parent={i === 0}
                data={{
                  values: item,
                }}
              />
            ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
