import { createContext, useEffect, useState } from "react";
import { ListCharacteristics } from "./components";
import { SelectableParameter } from "@interfaces/product";
import { useAppDispatch } from "store/hooks";
import { setCharacteristics } from "@global-state/product/slice";

export const characteristicsContext = createContext<{
  data: SelectableParameter[];
  switchChekedParameter: (
    id: SelectableParameter["id"],
    status: boolean,
  ) => void;
  switchChekedParameterValue: (
    id: SelectableParameter["id"],
    idValue: SelectableParameter["values"][0]["id"],
    status: boolean,
  ) => void;
} | null>(null);

export function Core({ data }: { data: SelectableParameter[] }) {
  const [parameters, setParameters] = useState<SelectableParameter[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setParameters(data);
  }, [data]);

  useEffect(() => {
    const characteristicsSelect: Record<string, number[]> = {};

    parameters.forEach((char) => {
      const valuesSelect: number[] = [];

      char.values.forEach((value) => {
        if (value.selected) {
          valuesSelect.push(value.id);
        }
      });

      if (valuesSelect.length > 0) {
        characteristicsSelect[char.id] = valuesSelect;
      }
    });

    dispatch(setCharacteristics(characteristicsSelect));
  }, [parameters]);

  return (
    <characteristicsContext.Provider
      value={{
        data: parameters,
        switchChekedParameter(id, status) {
          setParameters((state) =>
            state.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  selected: status,
                  values: item.values.map((value, index) => ({
                    ...value,
                    selected: index === 0 ? true : status,
                  })),
                };
              } else {
                return item;
              }
            }),
          );
        },
        switchChekedParameterValue(id, idValue, status) {
          setParameters((state) =>
            state.map((item) => {
              if (item.id === id) {
                let checkedValue = 0;

                item.values.forEach((value) => {
                  if (value.id === idValue) {
                    checkedValue += Number(status);
                  } else {
                    if (value.selected) {
                      checkedValue++;
                    }
                  }
                });

                return {
                  ...item,
                  values: item.values.map((value) => {
                    if (value.id === idValue) {
                      return {
                        ...value,
                        selected: !Boolean(checkedValue) || status,
                      };
                    } else {
                      if (item.multi_selection) {
                        return value;
                      } else {
                        return {
                          ...value,
                          selected: false,
                        };
                      }
                    }
                  }),
                  selected: item.values.length === checkedValue,
                };
              } else {
                return item;
              }
            }),
          );
        },
      }}
    >
      <ListCharacteristics data={parameters} />
    </characteristicsContext.Provider>
  );
}
