import { Tenants, TenantsDeleted } from "../../../components/account/dashboard";

import style from "./AccountDashboardPage.module.scss";

export function AccountDashboardPage() {
  return (
    <main className={style["page"]}>
      <Tenants />
      <TenantsDeleted />
    </main>
  );
}
