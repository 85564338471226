import { ProductButtons, ProductCheckbox } from "./components";
import cn from "classnames";

import style from "./RowProduct.module.scss";
import { ProductTable } from "@interfaces/product";

export function RowProduct({ data }: { data: ProductTable }) {
  return (
    <>
      <div className={cn(style["bodyColumn"], "!justify-start !px-0")}>
        <ProductCheckbox product={data} />
      </div>
      <div className={cn(style["bodyColumn"])} title={data.sku}>
        <span className="truncate">{data.sku}</span>
      </div>
      <div className={cn(style["bodyColumn"])} title={data.ua_name}>
        <span className="truncate">{data.ua_name}</span>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.buying_price)}
      >
        <span className="truncate">{data.buying_price} грн.</span>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.retail_price)}
      >
        <span className="truncate">{data.retail_price} грн.</span>
      </div>
      <div className={style["bodyColumn"]}>
        <div className={style["imageLimit"]}>
          <img src={data.picture} alt={data.ua_name} className={style["img"]} />
        </div>
      </div>
      <div className={cn(style["bodyColumn"], "px-0")}>
        <ProductButtons product={data} />
      </div>
    </>
  );
}
