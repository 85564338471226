import { useProductsAllHoroshopQuery } from "@RTKQuery/unloading/api";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import cn from "classnames";
import style from "./Table.module.scss";
import { Tbody, Thead } from "./components";

export function Table() {
  const [sortBy, setSortBy] = useState<
    | "price/asc"
    | "price/desc"
    | "category/asc"
    | "category/desc"
    | "ua_name/desc"
    | "ua_name/asc"
    | ""
  >("");
  const [queryParams, setQueryParams] = useSearchParams();

  const categoriesSelected = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject.categories,
  );

  const { data } = useProductsAllHoroshopQuery({
    categoriesIds: categoriesSelected,
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  useEffect(() => {
    setQueryParams(
      Object.assign({
        limit:
          queryParams.get("limit") ||
          window.localStorage.getItem("limitUnloadingPage") ||
          "20",
        offset: queryParams.get("offset") || "0",
      }),
    );
  }, []);

  useEffect(() => {
    if (data?.products.length === 0 && queryParams.get("offset") + "" !== "0") {
      setQueryParams(
        Object.assign(
          {
            limit:
              queryParams.get("limit") ||
              window.localStorage.getItem("limitUnloadingPage") ||
              "20",
            offset: "0",
          },
          queryParams.get("search") && {
            search: queryParams.get("search") || "",
          },
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem(
      "limitUnloadingPage",
      queryParams.get("limit") || "20",
    );
  }, [queryParams]);

  return (
    <div className={cn(style["table"], "scroll")}>
      <Thead data={data?.products} sortBy={sortBy} setSortBy={setSortBy} />
      <Tbody
        data={data?.products}
        sortBy={sortBy.split("/")[0]}
        order={sortBy.split("/")[1]}
      />
    </div>
  );
}
