import { Button, InputNoFormik } from "@ui";
import style from "./TopUpBalanceForm.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setBalanceTopUpValue } from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function TopUpBalanceForm() {
  const balanceTopUpValue = useAppSelector(
    (state) => state.temporalData.balance,
  );

  const dispatch = useAppDispatch();

  return (
    <>
      <div className={style["inputs"]}>
        <InputNoFormik
          value={balanceTopUpValue}
          setValue={(newValue) => {
            dispatch(
              setBalanceTopUpValue(newValue.replace(/[^0-9.]+|(\.\d*\.)/g, "")),
            );
          }}
          label="Сума поповнення $"
        />
        <InputNoFormik value="" setValue={() => {}} label="Промокод" disabled />
      </div>
      <div className="h-[42px] w-[180px] max-sm:w-full">
        <Button
          size="big"
          text="Поповнити"
          disabled={!balanceTopUpValue}
          onClick={() => {
            dispatch(
              showModal({
                name: "PaymentMethodModal",
              }),
            );
          }}
        />
      </div>
    </>
  );
}
