import { useParams } from "react-router-dom";
import { useReferenceBookValueAllQuery } from "@RTKQuery/reference-book-values/api";
import { ReferenceBookValue } from "@interfaces/refence-book";

export function TableValuesBody({
  children,
}: {
  children: (data: ReferenceBookValue) => JSX.Element;
}) {
  const { book } = useParams();
  const { data } = useReferenceBookValueAllQuery(Number(book || "-1"));

  return <>{data?.map((item) => children(item))}</>;
}
