import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useReferenceBookCreateMutation } from "@RTKQuery/reference-book/api";
import { Input, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва - обов'язкове поле"),
});

export function AddBookForm() {
  const [
    createDictionary,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useReferenceBookCreateMutation({
    fixedCacheKey: "add-book",
  });

  const { group } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        createDictionary({
          group_id: Number(group),
          name: values.name,
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      <Form>
        <div className="mb-[40px]">
          <Input formikName="name" label="Назва" inputType="text" />
        </div>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (error as unknown as { data: { error: string } }).data.error
              : ""
          }
        />
      </Form>
    </Formik>
  );
}
