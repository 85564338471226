import { api } from "..";

const balanceApi = api.injectEndpoints({
    endpoints: (build) => ({
        topUpBalance: build.mutation<{pageUrl: string}, {amount: number}>({
            query: (data) => {
                return {
                    url: "balance/top-up",
                    method: "POST",
                    body: data
                }
            }
        })
    }),
    overrideExisting: false,
});

export const {
    useBalanceQuery,
    useBalanceHistoryQuery,
    useTopUpBalanceMutation
} = balanceApi;