import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

import style from "./ForgotPasswordForm.module.scss";
import { axiosLog } from "@api";
import { BasicInputProps, Input, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
});

export function ForgotPasswordForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog("https://account-api.levelup.com.ua")
          .post(
            "/auth/forgot-password",
            {
              email: values.email,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response.data.ok) {
              setSubmitingStatus("success");

              setTimeout(() => {
                setSubmitingStatus("idle");
              }, 5000);
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
            console.error(err);

            setTimeout(() => {
              setSubmitingStatus("idle");
            }, 10000);
          });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input<BasicInputProps>
            formikName="email"
            label="E-mail"
            inputType="email"
            placeholder="youremail@gmail.com"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Скинути"
            successText="Успішно"
            errorText="Помилка"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
