import {
  ListTemplate,
  MultipleActionsTemplates,
} from "../../../../components/catalog/products-template";

import style from "./ProductsTemplateAllPage.module.scss";

export function ProductsTemplateAllPage() {
  return (
    <div className={style["page"]}>
      <ListTemplate />
      <MultipleActionsTemplates />
    </div>
  );
}
