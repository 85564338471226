import { useAppSelector } from "store/hooks";
import { Item } from "./components";

import style from "./List.module.scss";

export function List() {
  const tenants = useAppSelector((state) => state.superadmin.tenants);

  if (tenants.length > 0) {
    return (
      <table className={style["table"]}>
        <colgroup className={style["colgroup"]}>
          <col width="390" />
          <col width="260" />
          <col width="160" />
          <col />
        </colgroup>
        <thead className={style["thead"]}>
          <tr className={style["row"]}>
            <th className={style["col"]}>Назва</th>
            <th className={style["col"]}>Тип</th>
            <th className={style["col"]}>Баланс</th>
            <th className={style["col"]}></th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant) => (
            <Item key={tenant.uuid} data={tenant} />
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <div className={style["empty"]}>
        <p className={style["message"]}>Список пустий</p>
      </div>
    );
  }
}
