import { BigConteined, BigOutlined, Small } from "./components/buttons";

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "type"
  > {
  type?: "add" | "delete" | "edit";
  variant?: "contained" | "outlined";
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: "big" | "small";
  text?: string;
  disabled?: boolean;
  className?: string;
  icon?: (className: string) => JSX.Element;
  alignIcon?: "left" | "right";
  color?: "default" | "success" | "error";
}

export function Button({
  variant = "contained",
  size = "small",
  ...shared
}: Props) {
  if (size === "big") {
    if (variant === "contained") {
      return <BigConteined {...shared} />;
    } else {
      return <BigOutlined {...shared} />;
    }
  } else {
    return (
      <Small
        disabled={shared.disabled}
        onClick={shared.onClick}
        type={shared.type}
        icon={shared.icon}
      />
    );
  }
}
