import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { BasicInputs } from "./components";
import { ProductExtended } from "@interfaces/product";

const ValidationSchema = Yup.object().shape({
  sku: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Артикул - обов'язкове поле"),
  ua_name: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва UA - обов'язкове поле"),
  ru_name: Yup.string().max(36, "Максимальна довжина рядка 36 символів"),
  buying_price: Yup.number()
    .typeError(
      "Ціна покупки -  повинно бути числовим та може містити знаки після коми",
    )
    .required("Ціна покупки - обов'язкове поле"),
  old_price: Yup.number().typeError(
    "Стара ціна -  повинно бути числовим та може містити знаки після коми",
  ),
  retail_price: Yup.number()
    .typeError(
      "Роздрібна ціна -  повинно бути числовим та може містити знаки після коми",
    )
    .required("Роздрібна ціна - обов'язкове поле"),
  discount: Yup.number().typeError(
    "Знижка -  повинно бути числовим та може містити знаки після коми",
  ),
  ua_description: Yup.string(),
  ru_description: Yup.string(),
  category_id: Yup.string().required("Категорія - обов'язкове поле"),
});

export function BasicForm({
  product,
  submitRef,
}: {
  product?: ProductExtended;
  submitRef: React.RefObject<HTMLButtonElement>;
}) {
  const { categoryId } = useParams();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        sku: product?.sku || "",
        ua_name: product?.ua_name || "",
        ru_name: product?.ru_name || "",
        buying_price: product?.buying_price || "",
        old_price: product?.old_price || "",
        retail_price: product?.retail_price || "",
        discount: product?.discount || "",
        ua_description: product?.ua_description || "",
        ru_description: product?.ru_description || "",
        category_id: Number(categoryId) || product?.category_id || "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={() => {}}
      validateOnMount
    >
      <Form className="mb-[20px] w-full">
        <BasicInputs />
        <button type="submit" ref={submitRef} style={{ display: "none" }} />
      </Form>
    </Formik>
  );
}
