import { AllTemplateCheckbox, ItemTemplate } from "./components";
import cn from "classnames";
import style from "./ListTemplate.module.scss";
import { useProductsTemplateAllQuery } from "@RTKQuery/products-template/api";

export function ListTemplate() {
  const { data } = useProductsTemplateAllQuery();

  return (
    <div className="h-full">
      <div className={style["listHeader"]}>
        <AllTemplateCheckbox template={data} />
        <div className={style["label"]}>Шаблони</div>
      </div>
      <div className={cn(style["listBody"], "scroll")}>
        {data?.map((item) => <ItemTemplate key={item.id} template={item} />)}
      </div>
    </div>
  );
}
