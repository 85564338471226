import { useParams } from "react-router-dom";
import { AllBooksCheckbox, ItemBook } from "./components";
import cn from "classnames";
import style from "./ListBooks.module.scss";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import ReferenceBookGroup from "@interfaces/refence-book";

export function ListBooks() {
  const { data } = useReferenceBookAllQuery();

  const { group } = useParams();

  const groupItem: ReferenceBookGroup | undefined = data?.find((item) => {
    return item.id === Number(group || "-1");
  });

  return (
    <div className="h-full max-sm:h-fit">
      <div className={style["listHeader"]}>
        <AllBooksCheckbox group={groupItem} />
        <div className={style["label"]}>Довідники</div>
      </div>
      <div className={cn(style["listBody"], "scroll")}>
        {groupItem?.items.map((item) => (
          <ItemBook key={item.id} book={item} group={groupItem} />
        ))}
      </div>
    </div>
  );
}
