import { setCharacteristics } from "@global-state/product/slice";
import { SelectableParameter } from "@interfaces/product";
import { SwitchButton } from "@ui";
import { SelectTemplate } from "components/catalog/product";
import { useLazyProductsTemplateOneCharacteristicValuesQuery } from "querySlices/products-template/api";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";

export function CreateMod({
  children,
}: {
  children: (selectebleTemplateData: SelectableParameter[]) => JSX.Element;
}) {
  const [activeCharacteristics, setActiveCharacteristics] =
    useState<boolean>(false);
  const [templateParameters, setTemplateParameters] = useState<
    SelectableParameter[]
  >([]);

  const [trigger, { isError, isSuccess, isFetching, error }] =
    useLazyProductsTemplateOneCharacteristicValuesQuery();

  const dispatch = useAppDispatch();

  function handlerTrigger(id: number) {
    if (id >= 0) {
      trigger(id)
        .unwrap()
        .then((response) => {
          setTemplateParameters(
            response.characteristics.map((item) => ({
              ...item,
              selected: false,
              values: item.values.map((value, index) => ({
                ...value,
                selected: index === 0,
              })),
            })),
          );
        })
        .catch(() => {});
    } else {
      setTemplateParameters([]);
    }
  }

  useEffect(() => {
    if (!activeCharacteristics) {
      setTemplateParameters([]);
      dispatch(setCharacteristics({}));
    }
  }, [activeCharacteristics]);

  return (
    <div className="mb-[20px]">
      <div className="mb-[20px]">
        <SwitchButton
          label="Характеристики:"
          checked={activeCharacteristics}
          onChange={() => setActiveCharacteristics((state) => !state)}
        />
      </div>
      {activeCharacteristics && (
        <>
          <SelectTemplate
            handlerTrigger={handlerTrigger}
            result={{
              isError,
              isSuccess,
              isFetching,
              error,
            }}
          />
          {children(templateParameters)}
        </>
      )}
    </div>
  );
}
