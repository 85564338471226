import { Branch } from "./components";
import cn from "classnames";
import style from "./MenuProductCategory.module.scss";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { Button, SpoilerButton } from "@ui";
import { category_add_setData } from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function MenuProductCategory() {
  const { data } = useCategoriesAllQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  return (
    <div className={style["sidebar"]}>
      <div className={style["label"]}>Категорії</div>
      <div
        className={style["mobileLabel"]}
        onClick={() => setIsOpen((state) => !state)}
      >
        <p>Групи довідників</p>
        <SpoilerButton
          isOpen={isOpen}
          className={style["customSpoilerButton"]}
        />
      </div>
      <div className={cn(style["menuBox"], !isOpen && style["menuBox_close"])}>
        <nav className={cn(style["menu"], "scroll")}>
          <Branch
            key={0}
            treeIds={String(0)}
            actions={false}
            data={{
              id: 0,
              name: {
                uk: "Всі товари",
              },
              parent_id: null,
              children: [],
            }}
          />
          {data?.map((item) => (
            <Branch key={item.id} data={item} treeIds={String(item.id)} />
          ))}
        </nav>
        <div className={style["button"]}>
          {data?.length === 0 ? (
            <Button
              type="add"
              size="big"
              variant="outlined"
              text="Створіть першу категорію"
              onClick={() => {
                dispatch(category_add_setData(null));
                dispatch(
                  showModal({
                    name: "AddCategoryModal",
                  }),
                );
              }}
            />
          ) : (
            <Button
              type="add"
              size="big"
              variant="outlined"
              onClick={() => {
                dispatch(category_add_setData(null));
                dispatch(
                  showModal({
                    name: "AddCategoryModal",
                  }),
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
