import { api } from "..";
import {
  ReferenceBook,
  ReferenceBookValue,
} from "../../interfaces/refence-book";

const referenceBookValueApi = api.injectEndpoints({
  endpoints: (build) => ({
    referenceBookValueCreate: build.mutation<
      ReferenceBookValue[],
      {
        idBook: ReferenceBook["id"];
        listNames: Pick<ReferenceBookValue, "name">[];
      }
    >({
      query: ({ idBook, listNames }) => ({
        url: `dictionary/values/${idBook}`,
        method: "PUT",
        body: listNames,
      }),
      async onQueryStarted({ idBook }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookValueAll",
              idBook || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookValueUpdate: build.mutation<
      ReferenceBookValue[],
      { idBook: ReferenceBook["id"]; referenceBookValue: ReferenceBookValue[] }
    >({
      query: ({ idBook, referenceBookValue }) => ({
        url: `dictionary/values/${idBook}`,
        method: "PUT",
        body: referenceBookValue,
      }),
      async onQueryStarted({ idBook }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookValueAll",
              idBook || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    referenceBookValueDelete: build.mutation<
      ReferenceBookValue[],
      {
        idBook: ReferenceBook["id"];
        referenceBookValuesIds: ReferenceBookValue["id"][];
      }
    >({
      query: ({ idBook, referenceBookValuesIds }) => {
        const listItem = referenceBookValuesIds
          .map((id) => `items=${id}`)
          .join("&");

        return {
          url: `dictionary/values/${idBook}?${listItem}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ idBook }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "referenceBookValueAll",
              idBook || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useReferenceBookValueAllQuery,
  useReferenceBookValueCreateMutation,
  useReferenceBookValueUpdateMutation,
  useReferenceBookValueDeleteMutation,
} = referenceBookValueApi;
