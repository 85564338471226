import { useUnloadingsHoroshopCategoriesSetMutation } from "@RTKQuery/unloading/api";
import { HoroshopCategorySelect, SubmitButton } from "@ui";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

export function SetHoroshopCategoriesForm() {
  const { unloadingId } = useParams();
  const [searchParams] = useSearchParams();

  const [listValue, setListValue] = useState<string[]>([]);

  const [
    setHoroshopCategories,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useUnloadingsHoroshopCategoriesSetMutation({
    fixedCacheKey: "update-product-variations",
  });

  const activeModal = useAppSelector((state) => state.modalState.modalName);
  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );
  const selectCategories = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject.categories,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  useEffect(() => {
    if (!activeModal) {
      setListValue([]);
    }
  }, [activeModal]);

  return (
    <div>
      <div className="mb-[40px]">
        <HoroshopCategorySelect
          listValue={listValue}
          setListValue={setListValue}
        />
      </div>
      <div className="mx-auto max-w-[225px]">
        <SubmitButton
          type="button"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (
                  error as unknown as {
                    data: { error: string };
                  }
                ).data.error
              : ""
          }
          onClick={() => {
            setHoroshopCategories({
              limit: Number(searchParams.get("limit") || "20"),
              offset: Number(searchParams.get("offset") || "0"),
              search: searchParams.get("search") || undefined,
              unloading_id: Number(unloadingId || ""),
              product_ids: selectProduct,
              selectedProductCategoriesIds: selectCategories,
              categories: listValue,
            });
          }}
        />
      </div>
    </div>
  );
}
