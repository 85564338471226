import { Button } from "@ui";
import { Link, useParams } from "react-router-dom";

import style from "./UnloadingsAddButton.module.scss";

export function UnloadingsAddButton() {
  const { marketplaceId } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  return (
    <Link
      to={crm + `/unloading/${marketplaceId}/link/add`}
      className={style["buttonContainer"]}
    >
      <Button variant="contained" size="big" type="add" onClick={() => {}} />
    </Link>
  );
}
