import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActionsParameters.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/modal-state/slice";

export function MultipleActionsParameters() {
  const delete_characteristic = useAppSelector(
    (state) => state.temporalData.parameters.delete_characteristic,
  );
  const delete_variation = useAppSelector(
    (state) => state.temporalData.parameters.delete_variation,
  );

  const dispatch = useAppDispatch();

  if (
    [...delete_characteristic, ...delete_variation].some(
      (item) => item.tag === "multiple",
    )
  ) {
    return (
      <div className={style["multipleActions"]}>
        <div className={style["button"]}>
          <Button
            type="delete"
            size="big"
            onClick={() => {
              dispatch(
                showModal({
                  name: "DeleteParameterModal",
                  mode: "multiple",
                }),
              );
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
