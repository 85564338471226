import {
  HistoryBalanceItem,
  HistoryBalanceBody,
  HistoryBalanceWrapper,
  HistoryBalanceHead,
} from "./components";

export function HistoryBalance() {
  return (
    <HistoryBalanceWrapper>
      <>
        <HistoryBalanceHead />
        <HistoryBalanceBody>
          {(data) => <HistoryBalanceItem data={data} />}
        </HistoryBalanceBody>
      </>
    </HistoryBalanceWrapper>
  );
}
