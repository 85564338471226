import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import cn from "classnames";

import style from "./Select.module.scss";
import { SpoilerButton } from "../SpoilerButton";

export function Select({
  formikName,
  listValue,
  label,
  helperText,
}: {
  formikName: string;
  listValue: { id: number; value: string; label: string }[];
  label: JSX.Element | string;
  helperText?: (text: string) => JSX.Element;
}) {
  const [showListValue, setShowListValue] = useState<boolean>(false);

  const { setFieldValue } = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(formikName);

  function changeValue(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setFieldValue(formikName, e.currentTarget.dataset.value);
    setShowListValue(false);
  }

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowListValue(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div className="relative">
        <div
          data-select
          className={cn(
            style["container"],
            showListValue && "!border-input-main",
          )}
          onClick={() => {
            setShowListValue((state) => !state);
          }}
        >
          <input
            className={style["input"]}
            type="text"
            value={
              listValue.find((item) => item.value === field.value)?.label ||
              "-1"
            }
            readOnly
          />
          <SpoilerButton isOpen={showListValue} />
        </div>
        {meta.touched && meta.error ? helperText?.(meta.error || "") : null}
        <div
          className={cn(
            style["listValues"],
            showListValue ? "flex" : "hidden",
            "scroll",
          )}
        >
          {listValue.map((item) => (
            <div
              key={item.id}
              className={cn(
                style["value"],
                item.value === field.value && style["value_active"],
              )}
              data-value={item.value}
              onClick={changeValue}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
