import {
  AddCategoryModal,
  DeleteCategoryModal,
  DeleteProductModal,
  EditCategoryModal,
} from "@modals";
import {
  MenuProductCategory,
  MultipleActions,
  Pagination,
  ProductTable,
  TopPanel,
} from "../../../../components/catalog/products";

import style from "./ProductAllPage.module.scss";

export function ProductAllPage() {
  return (
    <main className={style["layout"]}>
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Товари</p>
        <MenuProductCategory />
        <TopPanel />
        <div className={style["tableBox"]}>
          <ProductTable />
          <Pagination />
          <MultipleActions />
        </div>
        <>
          <AddCategoryModal />
          <DeleteCategoryModal />
          <EditCategoryModal />
          <DeleteProductModal />
        </>
      </div>
    </main>
  );
}
