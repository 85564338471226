export function UnloadingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
    >
      <path
        d="M15.875 14.8125H15.8856M16.3 11.625H16.9375C17.9276 11.625 18.4227 11.625 18.8132 11.7867C19.3339 12.0024 19.7476 12.4161 19.9633 12.9368C20.125 13.3273 20.125 13.8224 20.125 14.8125C20.125 15.8026 20.125 16.2977 19.9633 16.6882C19.7476 17.2089 19.3339 17.6226 18.8132 17.8383C18.4227 18 17.9276 18 16.9375 18H4.1875C3.19738 18 2.70232 18 2.31179 17.8383C1.79112 17.6226 1.37743 17.2089 1.16176 16.6882C1 16.2977 1 15.8026 1 14.8125C1 13.8224 1 13.3273 1.16176 12.9368C1.37743 12.4161 1.79112 12.0024 2.31179 11.7867C2.70232 11.625 3.19738 11.625 4.1875 11.625H4.825M10.5625 12.6875V1M10.5625 12.6875L7.375 9.5M10.5625 12.6875L13.75 9.5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
