import { EditBookValueForm } from "@forms";
import { hideModal } from "@global-state/modal-state/slice";
import {
  referenceBook_deleteValues_clearData,
  referenceBook_deleteValues_deleteData,
  referenceBook_editValues_clearData,
  referenceBook_editValues_deleteData,
} from "@global-state/temporal-data/slice";
import { useReferenceBookValueUpdateMutation } from "@RTKQuery/reference-book-values/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "EditBookValueModal";

export function EditBookValueModal() {
  const [_, { isSuccess, reset }] = useReferenceBookValueUpdateMutation({
    fixedCacheKey: "edit-book-value",
  });

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const listItems = useAppSelector(
    (state) => state.temporalData.reference_book.edit_values,
  );
  let singleId: number =
    listItems.find((item) => item.tag === "single")?.id || -1;

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        if (activeMode === "single") {
          dispatch(
            referenceBook_deleteValues_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editValues_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
        } else {
          dispatch(referenceBook_deleteValues_clearData());
          dispatch(referenceBook_editValues_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeMode === "single") {
      dispatch(
        referenceBook_editValues_deleteData({
          id: singleId,
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Відредагувати значення"
    >
      <EditBookValueForm />
    </CRMModalLayout>
  );
}
