import { Action, ActionProps } from "../Action";

export function Remove(props: ActionProps) {
  return (
    <Action {...props}>
      <div className="group flex h-full w-full items-center justify-center">
        <svg
          className="stroke-[#B1B1B1] transition-colors ease-linear group-hover:stroke-black dark:group-hover:stroke-white"
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
        >
          <path
            d="M4.9936 6.3335V10.3335"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.65606 6.3335V10.3335"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 3.6665H11.6497"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.3312 3.6665H6.32483H10.3185V10.9998C10.3185 12.1044 9.42448 12.9998 8.32164 12.9998H4.32801C3.22521 12.9998 2.3312 12.1044 2.3312 10.9998V3.6665Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.32803 2.33333C4.32803 1.59695 4.92403 1 5.65923 1H6.99044C7.72567 1 8.32165 1.59695 8.32165 2.33333V3.66667H4.32803V2.33333Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Action>
  );
}
