import { Characteristic } from "@interfaces/products-template";
import { CharacteristicButtons, CharacteristicCheckbox } from "./components";

import style from "./ItemCharacteristic.module.scss";

export function ItemCharacteristic({
  characteristic,
}: {
  characteristic: Characteristic;
}) {
  return (
    <div className={style["variation"]}>
      <div className={style["box"]}>
        <CharacteristicCheckbox characteristic={characteristic} />
        <p className={style["value"]}>{characteristic.name}</p>
      </div>
      <CharacteristicButtons characteristic={characteristic} />
    </div>
  );
}
