import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import style from "./LoginForm.module.scss";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { axiosLog } from "@api";
import { setSuperadminAll } from "@global-state/superadmin/slice";
import { setSuperadminAllDTO } from "@global-state/superadmin/dto/setSuperadminAll.dto";
import { BasicInputProps, Input, PasswordInputProps, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
  password: Yup.string().required("Пароль - обов'язкове поле"),
});

export function LoginForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog("https://account-api.levelup.com.ua")
          .post(
            "/auth/login",
            {
              email: values.email,
              password: values.password,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response.data.ok) {
              window.localStorage.setItem(
                "access_token",
                response.data.access_token,
              );
              window.localStorage.setItem(
                "refresh_token",
                response.data.refresh_token,
              );
              setSubmitingStatus("success");

              dispatch(setSuperadminAll(setSuperadminAllDTO(response.data)));
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
            console.error(err);

            setTimeout(() => {
              setSubmitingStatus("idle");
            }, 10000);
          });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input<BasicInputProps>
            formikName="email"
            label="E-mail"
            inputType="email"
          />
          <Input<PasswordInputProps>
            formikName="password"
            label="Пароль"
            inputType="password"
          />
          <div className={style["forgotPasswordLink"]}>
            <Link
              to={
                process.env.NODE_ENV === "development"
                  ? "/account/auth/forgot-password"
                  : "/auth/forgot-password"
              }
            >
              <span className={style["textLink"]}>Забули пароль?</span>
            </Link>
          </div>
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Вхід"
            successText="Успішно"
            errorText="Не вдалось увійти"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
