import {
  ListBooks,
  MultipleActionsBooks,
} from "../../../../components/catalog/reference-book";

import style from "./ReferenceBookGroupPage.module.scss";

export function ReferenceBookGroupPage() {
  return (
    <div className={style["page"]}>
      <ListBooks />
      <MultipleActionsBooks />
    </div>
  );
}
