import { api } from "..";
import ProductsTemplate from "../../interfaces/products-template";

const productsTemplateApi = api.injectEndpoints({
  endpoints: (build) => ({
    productsTemplateCreate: build.mutation<
      ProductsTemplate[],
      ProductsTemplate["name"]
    >({
      query: (templateName) => ({
        url: "patterns",
        method: "POST",
        body: {
          name: templateName,
        },
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    productsTemplateUpdate: build.mutation<
      ProductsTemplate[],
      Pick<ProductsTemplate, "id" | "name">
    >({
      query: ({ id, ...name }) => ({
        url: `patterns/${id}`,
        method: "PUT",
        body: name,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    productsTemplateDelete: build.mutation<
      ProductsTemplate[],
      ProductsTemplate["id"][]
    >({
      query: (templateIds) => {
        const listItem = templateIds.map((id) => `items=${id}`).join("&");

        return {
          url: `patterns?${listItem}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateAll",
              undefined,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useProductsTemplateAllQuery,
  useProductsTemplateOneQuery,
  useLazyProductsTemplateOneCharacteristicValuesQuery,
  useLazyProductsTemplateOneVariationValuesQuery,
  useProductsTemplateCreateMutation,
  useProductsTemplateUpdateMutation,
  useProductsTemplateDeleteMutation,
} = productsTemplateApi;
