import { useState } from "react";
import cn from "classnames";
import { RiLoader5Fill } from "react-icons/ri";

import style from "./LogoutButton.module.scss";
import { useAppDispatch } from "store/hooks";
import { axiosLog } from "@api";
import { clearCRMUserAll } from "@global-state/CRMUser/slice";

export function LogoutButton() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const dispatch = useAppDispatch();

  const crm = window.localStorage.getItem("subdomain");

  function logout() {
    setSubmitingStatus("load");

    axiosLog(`https://${crm}.api.levelup.com.ua`)
      .post(
        "/auth/logout",
        {
          access_token: window.localStorage.getItem("access_token_crm") || "",
          refresh_token: window.localStorage.getItem("refresh_token_crm") || "",
        },
        {
          headers: {
            Authorization:
              window.localStorage.getItem("access_token_crm") || "",
          },
        },
      )
      .then(() => {
        window.localStorage.removeItem("access_token_crm");
        window.localStorage.removeItem("refresh_token_crm");
        dispatch(clearCRMUserAll());
      })
      .catch((err) => {
        setSubmitingStatus({
          status: "failure",
          errorText: err?.response?.data?.error || "Error",
        });
        console.error(err);
      });
  }

  return (
    <button
      className={cn(
        style["buttonLogout"],
        submitingStatus === "idle" && style["buttonLogout_uninitialized"],
        submitingStatus === "load" && style["buttonLogout_loaded"],
        typeof submitingStatus === "object" && style["buttonLogout_error"],
      )}
      disabled={submitingStatus === "load"}
      onClick={logout}
    >
      {submitingStatus === "load" ? (
        <div className="w-[15px]">
          <RiLoader5Fill className="h-full w-full animate-spin" />
        </div>
      ) : (
        <span>
          {submitingStatus === "idle"
            ? "Вийти"
            : "" || typeof submitingStatus === "object"
              ? "Не вдалось вийти"
              : ""}
        </span>
      )}
    </button>
  );
}
