import { useAppSelector } from "store/hooks";
import { List } from "./components";

import style from "./TenantsDeleted.module.scss";

export function TenantsDeleted() {
  const tenantsDeleted = useAppSelector(
    (state) => state.superadmin.deleted_tenants,
  );

  if (tenantsDeleted.length !== 0) {
    return (
      <div className={style["container"]}>
        <p className={style["title"]}>CRM, заплановані до видалення</p>
        <List />
      </div>
    );
  } else {
    return null;
  }
}
