import style from "./LongSettings.module.scss";
import { DynamicMarkup, FixedMarkup } from "./components";

export function LongSettings() {
  return (
    <div className={style["container"]}>
      <FixedMarkup />
      <div className={style["divider"]}></div>
      <DynamicMarkup />
    </div>
  );
}
