import { Link } from "react-router-dom";
import { LoginForm } from "@forms";

import style from "./LoginPage.module.scss";

export function LoginPage() {
  return (
    <main className={style["page"]}>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>
          Вхід в акаунт <span className="max-sm:hidden">LevelUp</span>
        </h3>
        <div className={style["logo"]}>
          <div className={style["imageLimit"]}>
            <img
              src="/images/logoMain.svg"
              alt="logo"
              className={style["image"]}
            />
          </div>
          <p className={style["label"]}>LevelUp</p>
        </div>
        <LoginForm />
        <div className={style["registerLink"]}>
          <p className={style["label"]}>Немає акаунту?</p>
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/auth/register"
                : "/auth/register"
            }
            className={style["link"]}
          >
            Зареєструватись
          </Link>
        </div>
      </div>
    </main>
  );
}
