import { ProductExtended, SelectableParameter } from "@interfaces/product";
import { useMemo } from "react";

export function EditMod({
  dataProductCharacterictics,
  children,
}: {
  children: (templateData: SelectableParameter[]) => JSX.Element;
  dataProductCharacterictics: ProductExtended["characteristics"];
}) {
  const selectebleData: SelectableParameter[] = useMemo<
    SelectableParameter[]
  >(() => {
    return dataProductCharacterictics.map((item) => {
      let countValueSelect = 0;

      return {
        id: item.id,
        multi_selection: item.multi_selection,
        name: item.name,
        values: item.values.map<SelectableParameter["values"][0]>((value) => {
          countValueSelect += Number(value.selected);

          return {
            id: value.id,
            name: value.value,
            selected: value.selected,
          };
        }),
        selected: countValueSelect === item.values.length,
      };
    });
  }, [dataProductCharacterictics]);

  return (
    <div className="mb-[20px]">
      <p className="text-[13px] font-normal text-[#838383]">Характистики: </p>
      {children(selectebleData)}
    </div>
  );
}
