import {
  clearSelectProduct,
  setSelectProduct,
} from "@global-state/unloading/slice";
import { ProductHoroshop } from "@interfaces/unloading";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllProductsCheckbox({
  products,
}: {
  products: ProductHoroshop[] | undefined;
}) {
  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={products?.length === selectProduct.length}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            setSelectProduct(products?.map((product) => product.id) || []),
          );
        } else {
          dispatch(clearSelectProduct());
        }
      }}
    />
  );
}
