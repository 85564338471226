import { useUnloadingAllQuery } from "@RTKQuery/unloading/api";
import { useParams } from "react-router-dom";
import { UnloadingsItem } from "../UnloadingsItem";
import cn from "classnames";
import style from "./UnloadingsList.module.scss";

export function UnloadingsList() {
  const { marketplaceId } = useParams();

  const { data } = useUnloadingAllQuery(Number(marketplaceId));

  return (
    <div className={cn(style["list"], "scroll")}>
      {data?.map((item) => <UnloadingsItem key={item.id} data={item} />)}
    </div>
  );
}
