import {
  parameters_delete_addData,
  parameters_delete_deleteData,
} from "@global-state/temporal-data/slice";
import { Characteristic } from "@interfaces/products-template";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function CharacteristicCheckbox({
  characteristic,
}: {
  characteristic: Characteristic;
}) {
  const delete_characteristic_ids = useAppSelector(
    (state) => state.temporalData.parameters.delete_characteristic,
  )
    .filter((item) => item.tag === "multiple")
    .map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={delete_characteristic_ids.includes(characteristic.id)}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            parameters_delete_addData({
              data: [
                {
                  id: characteristic.id,
                  tag: "multiple",
                },
              ],
              parameterType: "characteristic",
            }),
          );
        } else {
          dispatch(
            parameters_delete_deleteData({
              data: {
                id: characteristic.id,
                singleOnly: false,
              },
              parameterType: "characteristic",
            }),
          );
        }
      }}
    />
  );
}
