import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import cn from "classnames";
import style from "./SelectTemplate.module.scss";
import { useProductsTemplateAllQuery } from "@RTKQuery/products-template/api";
import { SelectNoFormik, SubmitButton } from "@ui";

interface Value {
  id: number;
  value: string;
  label: string;
}

const defaultValue = {
  id: -1,
  value: "null",
  label: "Шаблон не вибрано",
};

export function SelectTemplate({
  handlerTrigger,
  result,
}: {
  handlerTrigger: (id: number) => void;
  result: {
    isError: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  };
}) {
  const [selectValue, setSelectValue] = useState<Value>(defaultValue);

  const { data: dataTemplates = [] } = useProductsTemplateAllQuery(undefined, {
    selectFromResult: ({ data, ...args }) => ({
      data: data?.map<Value>((item) => ({
        id: item.id,
        value: String(item.id),
        label: item.name,
      })),
      ...args,
    }),
  });

  useEffect(() => {
    handlerTrigger(selectValue.id);
  }, [selectValue]);

  return (
    <div className={style["selectTemplate"]}>
      <SelectNoFormik
        className="w-[50%] max-sm:w-full"
        label="Доступні шаблони"
        currentValue={selectValue}
        listValue={[defaultValue, ...dataTemplates]}
        setCurrentValue={(newValue) => setSelectValue(newValue)}
      />
      <div
        className={cn(
          style["buttonContainer"],
          selectValue.id === -1 && "!hidden",
        )}
      >
        <SubmitButton
          className={style["customSubmitButton"]}
          type="button"
          isUninitialized={false}
          isError={result.isError && !result.isFetching}
          isSuccess={result.isSuccess && !result.isFetching}
          isLoading={result.isFetching}
          successText="Дані отримано"
          errorText={(() => {
            let error = "";

            try {
              error = result.isError
                ? (result.error as unknown as { data: { error: string } }).data
                    .error
                : "";
            } catch (err) {
              error = "Непередбачувана помилка";
            }

            return error;
          })()}
          uninitializedText=""
          errorMesage=""
        />
      </div>
    </div>
  );
}
