import { Link } from "react-router-dom";
import { TemplateButtons, TemplateCheckbox } from "./components";

import style from "./ItemTemplate.module.scss";
import ProductsTemplate from "@interfaces/products-template";

export function ItemTemplate({
  template,
}: {
  template: Pick<ProductsTemplate, "id" | "name">;
}) {
  const crm = window.localStorage.getItem("subdomain");

  return (
    <div className={style["template"]}>
      <div className={style["box"]}>
        <TemplateCheckbox template={template} />
        <Link
          to={
            process.env.NODE_ENV === "development"
              ? `/${crm}/products-template/${template.id}/`
              : `/products-template/${template.id}/`
          }
          title={template.name}
          className={style["link"]}
        >
          {template.name}
        </Link>
      </div>
      <TemplateButtons template={template} />
    </div>
  );
}
