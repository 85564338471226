import { Link } from "react-router-dom";
import { useEffect } from "react";

import style from "./VerifyPage.module.scss";
import { VerifyForm } from "@forms";

export function VerifyPage() {
  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      window.localStorage.removeItem("service_token");
    });
  }, []);

  return (
    <main className={style["page"]}>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>Введіть код верифікації</h3>
        <VerifyForm />
        <p className="text-center">
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/auth/login"
                : "/auth/login"
            }
            className={style["loginLink"]}
          >
            В мене вже є акаунт
          </Link>
        </p>
      </div>
    </main>
  );
}
