export function BigAddIcon({ className }: { className?: string }) {
  return (
    <svg
      width={12}
      height={12}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="inherit"
    >
      <path
        d="M9.87406 4.23396C10.4959 4.23396 11 4.73806 11 5.35991C11 5.98175 10.4959 6.48585 9.87406 6.48585H1.12594C0.504102 6.48585 0 5.98175 0 5.35991C0 4.73806 0.504102 4.23396 1.12594 4.23396H9.87406ZM5.50551 0C6.21468 0 6.78958 0.574897 6.78958 1.28407V9.71593C6.78958 10.4251 6.21468 11 5.50551 11C4.79634 11 4.22144 10.4251 4.22144 9.71593V1.28407C4.22144 0.574898 4.79634 0 5.50551 0Z"
        fill="inherit"
      />
    </svg>
  );
}
