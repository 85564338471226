import { ButtonHTMLAttributes } from "react";
import { RiLoader5Fill } from "react-icons/ri";
import cn from "classnames";

import style from "./SubmitButton.module.scss";

export function SubmitButton({
  isUninitialized,
  uninitializedText,
  isLoading,
  isSuccess,
  successText,
  isError,
  errorText,
  errorMesage,
  hFull,
  className,
  disabled,
  ...args
}: {
  isUninitialized: boolean;
  uninitializedText: string;
  isLoading: boolean;
  isSuccess: boolean;
  successText: string;
  isError: boolean;
  errorText: string;
  errorMesage: string;

  hFull?: boolean;
  className?: string;

  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
}) {
  return (
    <>
      <button
        {...args}
        disabled={disabled}
        className={cn(
          style["button"],
          hFull ? style["hFull_on"] : style["hFull_off"],
          isUninitialized && !disabled && style["uninitialized"],
          isLoading && style["loading"],
          isSuccess && style["success"],
          isError && style["error"],
          disabled && !isLoading ? style["disabled_on"] : style["disabled_off"],
          className,
        )}
      >
        {isUninitialized ? uninitializedText : ""}
        {isLoading ? (
          <div className={style["loader"]}>
            <RiLoader5Fill className={style["icon"]} />
          </div>
        ) : (
          ""
        )}
        {isSuccess ? successText : ""}
        {isError ? errorText : ""}
      </button>
      {errorMesage ? (
        <p className={style["errorMessage"]}>{errorMesage}</p>
      ) : null}
    </>
  );
}
