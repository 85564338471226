import { useState, memo } from "react";

import style from "./Timer.module.scss";

export const Timer = memo(({ setEnd }: { setEnd: () => void }) => {
  const [totalTime, setTotalTime] = useState<number>(299000);

  if (totalTime !== 0) {
    setTimeout(() => {
      setTotalTime((state) => state - 1000);
    }, 1000);
  } else {
    setEnd();
  }

  return (
    <div className={style["timer"]}>
      {String(Math.floor(totalTime / 60000)).padStart(2, "0")}:
      {String(Math.floor((totalTime % 60000) / 1000)).padStart(2, "0")}
    </div>
  );
});
