import cn from "classnames";

import { Props } from "../../../Button";
import { BigAddIcon, DeleteIcon, EditIcon } from "../../icons";

import style from "./BigOutlined.module.scss";

export function BigOutlined({
  onClick,
  disabled,
  text,
  type,
  className,
  icon,
  alignIcon = "left",
  color = "default",
  ...args
}: Omit<Props, "size" | "variant">) {
  return (
    <button
      {...args}
      type="button"
      onClick={onClick}
      className={cn(
        style["button"],
        disabled ? style["disabled_on"] : style["disabled_off"],
        color === "success" && style["success"],
        color === "error" && style["error"],
        className,
      )}
      disabled={disabled}
    >
      {text && alignIcon === "right" ? (
        <span className={style["text"]}>{text}</span>
      ) : null}
      {icon || type ? (
        <span className={style["icon"]}>
          {icon
            ? icon("h-[14px] w-[14px] max-sm:h-[12px] max-sm:w-[12px]")
            : null}
          {type === "add" ? (
            <BigAddIcon className="h-[14px] w-[14px] max-sm:h-[12px] max-sm:w-[12px]" />
          ) : null}
          {type === "delete" ? (
            <DeleteIcon className="h-[14px] w-[13px] max-sm:h-[14px] max-sm:w-[13px]" />
          ) : null}
          {type === "edit" ? (
            <EditIcon className="h-[14px] w-[14px] max-sm:h-[13px] max-sm:w-[13px]" />
          ) : null}
        </span>
      ) : null}
      {text && alignIcon === "left" ? (
        <span className={style["text"]}>{text}</span>
      ) : null}
    </button>
  );
}
