import {
  product_delete_clearData,
  product_delete_setData,
} from "@global-state/temporal-data/slice";
import { ProductTable } from "@interfaces/product";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllProductsCheckbox({
  products,
}: {
  products: ProductTable[] | undefined;
}) {
  const delete_books = useAppSelector(
    (state) => state.temporalData.product.delete,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        products?.length ===
        delete_books.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            product_delete_setData(
              products?.map((product) => ({
                id: product.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(product_delete_clearData());
        }
      }}
    />
  );
}
