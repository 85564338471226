import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProductDeleteVariationsMutation } from "@RTKQuery/products/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./DeleteVariationModal.module.scss";
import { Button, SubmitButton } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import {
  product_selected_variation_clearData,
  product_selected_variation_deleteData,
} from "@global-state/temporal-data/slice";
import { hideModal } from "@global-state/modal-state/slice";

const MODAL_NAME = "DeleteVariationModal";

export function DeleteVariationModal() {
  const { categoryId, productId, variationId } = useParams();
  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  const [
    deleteProducts,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductDeleteVariationsMutation({
    fixedCacheKey: "delete-product-variations",
  });

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const product_variation_delete_item = useAppSelector(
    (state) => state.temporalData.product.selected_variation,
  );
  let listIds: number[] = [];

  if (activeMode === "single") {
    listIds = [
      product_variation_delete_item.childIds.find(
        (item) => item.tag === "single",
      )?.id || -1,
    ];
  } else {
    listIds = Array.from(
      new Set(product_variation_delete_item.childIds.map((item) => item.id)),
    );
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (
        variationId &&
        product_variation_delete_item.childIds.some(
          (item) => item.id === Number(variationId),
        )
      ) {
        navigate(crm + `/category/${categoryId}/product/${productId}`);
      }

      if (!activeModal) {
        if (activeMode === "single") {
          dispatch(
            product_selected_variation_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
        } else {
          dispatch(product_selected_variation_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeMode === "single") {
      dispatch(
        product_selected_variation_deleteData({
          id: listIds[0],
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити вибрані варіації?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                deleteProducts({
                  parentId: product_variation_delete_item.parentId,
                  childIds: listIds,
                });
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
