import { SelectableParameter } from "@interfaces/product";
import { ListCharacteristicsValuesItem } from "..";

import style from "./ListCharacteristicsValues.module.scss";

export function ListCharacteristicsValues({
  data,
  parent,
}: {
  data: SelectableParameter["values"];
  parent: SelectableParameter;
}) {
  return (
    <div className={style["list"]}>
      {data.map((item) => (
        <ListCharacteristicsValuesItem
          key={item.id}
          data={item}
          parent={parent}
        />
      ))}
    </div>
  );
}
