import { TbLoaderQuarter } from "react-icons/tb";

import style from "./LoadingBackdrop.module.scss";

export function LoadingBackdrop() {
  return (
    <div className={style["backdrop"]}>
      <div className={style["loader"]}>
        <TbLoaderQuarter className={style["icon"]} />
      </div>
    </div>
  );
}
