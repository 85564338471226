import { useSortable } from "@dnd-kit/sortable";
import { motion } from "framer-motion";
import { useEffect } from "react";
import cn from "classnames";
import style from "./ItemDndImage.module.scss";
import { Handle, Remove } from "./components";
import { Picture } from "@global-state/product/interface";

const initialStyles = {
  x: 0,
  y: 0,
  scale: 1,
};

export function ItemDndImage({
  data,
  baseStyles,
  onRemove,
}: {
  data: Picture;
  baseStyles: string;
  onRemove: (id: string) => void;
}) {
  const { setNodeRef, listeners, transform, isDragging, setActivatorNodeRef } =
    useSortable({
      id: data.id,
      transition: null,
    });

  useEffect(() => {
    document.body.classList.add(style["noDragging"]);
  }, []);

  useEffect(() => {
    if (!isDragging) {
      document.body.classList.add(style["noDragging"]);
      return;
    } else {
      document.body.classList.remove(style["noDragging"]);
    }

    document.body.style.cursor = "grabbing";

    return () => {
      document.body.style.cursor = "";
      document.body.classList.remove(style["noDragging"]);
    };
  }, [isDragging]);

  return (
    <motion.li
      className={cn(style["itemDndImage"], baseStyles)}
      style={{
        backgroundImage: `url(${data.url})`,
      }}
      ref={setNodeRef}
      layoutId={data.id}
      animate={
        transform
          ? {
              x: transform.x,
              y: transform.y,
              scale: isDragging ? 1.05 : 1,
              zIndex: isDragging ? 1 : 0,
              boxShadow: isDragging
                ? "0 0 0 1px rgba(63, 63, 68, 0.05), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
                : undefined,
            }
          : initialStyles
      }
      transition={{
        duration: !isDragging ? 0.25 : 0,
        easings: {
          type: "spring",
        },
        scale: {
          duration: 0.25,
        },
        zIndex: {
          delay: isDragging ? 0 : 0.25,
        },
      }}
    >
      <span></span>
      <span className={style["actions"]}>
        <Handle
          className={style["handle"]}
          ref={setActivatorNodeRef}
          {...listeners}
          cursor={isDragging ? "grabbing" : "grab"}
        />
        <Remove className={style["remove"]} onClick={() => onRemove(data.id)} />
      </span>
    </motion.li>
  );
}
