import { BalanceHistoryRecord } from "@interfaces/balance";
import { useBalanceHistoryQuery } from "@RTKQuery/balance/api";

export function HistoryBalanceBody({
  children,
}: {
  children: (data: BalanceHistoryRecord) => JSX.Element;
}) {
  const { data } = useBalanceHistoryQuery();

  return (
    <>
      {(
        [
          {
            id: 1,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 2,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
          {
            id: 3,
            change_amount: 100,
            change_date: "2019-08-24T14:15:22Z",
            description: "string",
            transaction_type: "top_up",
          },
        ] as BalanceHistoryRecord[]
      ).map((item) => children(item))}
    </>
  );
}
