import {
  referenceBook_deleteBooks_clearData,
  referenceBook_deleteBooks_setData,
  referenceBook_editBooks_clearData,
  referenceBook_editBooks_setData,
} from "@global-state/temporal-data/slice";
import ReferenceBookGroup from "@interfaces/refence-book";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllBooksCheckbox({
  group,
}: {
  group: ReferenceBookGroup | undefined;
}) {
  const delete_books = useAppSelector(
    (state) => state.temporalData.reference_book.delete_books,
  );
  const edit_books = useAppSelector(
    (state) => state.temporalData.reference_book.edit_books,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        group?.items.length ===
          delete_books.filter((item) => item.tag === "multiple").length &&
        group?.items.length ===
          edit_books.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteBooks_setData(
              group?.items.map((book) => ({
                id: book.id,
                tag: "multiple",
              })) || [],
            ),
          );
          dispatch(
            referenceBook_editBooks_setData(
              group?.items.map((book) => ({
                id: book.id,
                name: book.name,
                group_id: group.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(referenceBook_deleteBooks_clearData());
          dispatch(referenceBook_editBooks_clearData());
        }
      }}
    />
  );
}
