import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

import style from "./SubMenu.module.scss";
import { useEffect, useState } from "react";
import { SpoilerButton } from "@ui";

export function SubMenu({
  icon,
  name,
  links,
  setCurrentPage,
}: {
  icon: JSX.Element;
  name: string;
  links: {
    text: string;
    link: string;
    compareIndex?: number;
    icon: JSX.Element;
  }[];
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    links.forEach((link) => {
      if (compareLink(link)) {
        setCurrentPage?.(link.text);
      }
    });

    setIsOpen(false);
  }, [location]);

  return (
    <div className={style["subMenu"]}>
      <Link
        to={links[0].link}
        className={cn(
          style["mainLink"],
          links.some((link) => compareLink(link)) && style["mainLink_active"],
        )}
      >
        {icon}
      </Link>
      {links.length > 1 ? (
        <div
          className={cn(
            style["mainLinkMobile"],
            links.some((link) => compareLink(link)) &&
              style["mainLinkMobile_active"],
          )}
          onClick={() => setIsOpen((state) => !state)}
        >
          <div className="flex items-center gap-[15px]">
            {icon}
            <span>{name}</span>
          </div>
          <SpoilerButton isOpen={isOpen} height={20} />
        </div>
      ) : (
        <Link
          to={links[0].link}
          className={cn(
            style["mainLinkMobile"],
            links.some((link) => compareLink(link)) &&
              style["mainLinkMobile_active"],
          )}
          onClick={() => setIsOpen((state) => !state)}
        >
          <div className="flex items-center gap-[15px]">
            {icon}
            <span>{name}</span>
          </div>
        </Link>
      )}
      {links.length > 1 ? (
        <>
          <div
            className={cn(
              style["hoverHelper"],
              isOpen && style["hoverHelper_show"],
            )}
          >
            <div className={style["box"]}>
              <p className={style["title"]}>{name}</p>
              <div className={style["links"]}>
                {links.map((link, i) => {
                  return (
                    <Link
                      key={i}
                      to={link.link.replace(":", "")}
                      className={cn(
                        style["link"],
                        compareLink(link) && style["link_active"],
                      )}
                    >
                      <div className={style["linkIcon"]}>{link.icon}</div>
                      <p className={style["linkText"]}>{link.text}</p>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={cn(style["arrow"], isOpen && style["arrow_show"])}
          ></div>
        </>
      ) : null}
    </div>
  );

  function compareLink(link: {
    text: string;
    link: string;
    compareIndex?: number;
  }) {
    return (
      location.pathname.slice(0, link.compareIndex) ===
      link.link.slice(0, link.compareIndex)
    );
  }
}
