import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "../../store/store";

const initialState: {
  modalName: string,
  mode?: string
} = {
  modalName: "",
  mode: ""
};

const modalStateSlice = createSlice({
  initialState,
  name: "modalState",
  reducers: {
    showModal: (state, action: SliceAction<{
      name: string,
      mode?: string
    }>) => {
      state.modalName = action.payload.name;
      state.mode = action.payload.mode;
    },
    hideModal: (state) => {
      state.modalName = "";
      state.mode = "";
    },
  },
});

const { reducer, actions } = modalStateSlice;

export default reducer;

export const {
  showModal,
  hideModal
} = actions;
