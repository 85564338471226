import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActions.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/modal-state/slice";

export function MultipleActions() {
  const delete_products = useAppSelector(
    (state) => state.temporalData.product.delete,
  );

  const dispatch = useAppDispatch();

  if (delete_products.some((item) => item.tag === "multiple")) {
    return (
      <div className={style["multipleActions"]}>
        <div className={style["button"]}>
          <Button
            type="delete"
            size="big"
            text="Видалити"
            onClick={() => {
              dispatch(
                showModal({
                  name: "DeleteProductModal",
                  mode: "multiple",
                }),
              );
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
