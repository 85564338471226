import { useContext } from "react";
import { variationsContext } from "../../Core";
import cn from "classnames";
import style from "./ListVariationsValuesItem.module.scss";
import { SelectableParameter } from "@interfaces/product";
import { Checkbox } from "@ui";

export function ListVariationsValuesItem({
  data,
  parentId,
}: {
  data: SelectableParameter["values"][0];
  parentId: SelectableParameter["id"];
}) {
  const context = useContext(variationsContext);

  return (
    <div className={style["limit"]}>
      <Checkbox
        label={(isChecked) => (
          <p
            className={cn(style["customLabel"], isChecked && style["checked"])}
          >
            {data.name}
          </p>
        )}
        checked={data.selected}
        onChange={(e) => {
          context?.switchChekedParameterValue(
            parentId,
            data.id,
            e.target.checked,
          );
        }}
      />
    </div>
  );
}
