import { redirect } from "react-router-dom";

import {
  OrderPage,
  CustomerPage,
  ClientsPage,
  SuppliersPage,
  ProductAllPage,
  ProductOnePage,
  ReferenceBookGroupPage,
  SettingsPage,
  SettingsOrderPage,
  SettingsSourcesPage,
  SettingsCommunicationPage,
  SettingsFinancePage,
  SettingsRolesPage,
  SettingsAccountStatusPage,
  AnalyticsProductsPage,
  AnalyticsOrderPage,
  AnalyticsManagersPage,
  AnalyticsSuppliersPage,
  ReferenceBookValuesPage,
  ProductsTemplateAllPage,
  ProductsTemplateOnePage,
  BalancePage,
} from "../../pages";

import {
  CreateProduct,
  EditProduct,
} from "../../pages/catalog/products/ProductOnePage/ProductOnePage";
import { CRMMainLayout } from "../../pages/layout/crm";
import {
  ProductTemplateLayout,
  ReferenceBookLayout,
  UnloadingAllPage,
  UnloadingOnePage,
} from "../../pages/catalog";
import {
  CreateUnloading,
  EditUnloading,
} from "pages/catalog/unloading/UnloadingOnePage/UnloadingOnePage";

const routes = (crm: string) => [
  {
    path: "orders",
    element: <OrderPage />,
  },
  {
    path: "customers",
    element: <CustomerPage />,
  },
  {
    path: "clients",
    element: <ClientsPage />,
  },
  {
    path: "suppliers",
    element: <SuppliersPage />,
  },
  {
    path: "category",
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath =
        process.env.NODE_ENV === "development"
          ? `/${crm}/category`
          : "/category";

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(
          rootPath +
            "/" +
            (window.localStorage.getItem("categoryProductsPage") || "0"),
        );
      }

      return null;
    },
    children: [
      {
        path: ":categoryId",
        element: <ProductAllPage />,
      },
    ],
  },
  {
    path: "category/:categoryId/product/:productId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "category/:categoryId/product/:productId/variation/:variationId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "category/:categoryId/product/add",
    element: (
      <CreateProduct>
        <ProductOnePage />
      </CreateProduct>
    ),
  },
  {
    path: "products-template",
    element: <ProductTemplateLayout />,
    children: [
      {
        index: true,
        element: <ProductsTemplateAllPage />,
      },
      {
        path: ":id",
        element: <ProductsTemplateOnePage />,
      },
    ],
  },
  {
    path: "reference-book",
    element: <ReferenceBookLayout />,
    children: [
      {
        path: ":group",
        element: <ReferenceBookGroupPage />,
      },
      {
        path: ":group/values/:book",
        element: <ReferenceBookValuesPage />,
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsPage />,
  },
  {
    path: "settings/order",
    element: <SettingsOrderPage />,
  },
  {
    path: "settings/sources",
    element: <SettingsSourcesPage />,
  },
  {
    path: "settings/communication",
    element: <SettingsCommunicationPage />,
  },
  {
    path: "settings/finance",
    element: <SettingsFinancePage />,
  },
  {
    path: "settings/roles",
    element: <SettingsRolesPage />,
  },
  {
    path: "settings/account-status",
    element: <SettingsAccountStatusPage />,
  },
  {
    path: "analytics/products",
    element: <AnalyticsProductsPage />,
  },
  {
    path: "analytics/order",
    element: <AnalyticsOrderPage />,
  },
  {
    path: "analytics/managers",
    element: <AnalyticsManagersPage />,
  },
  {
    path: "analytics/suppliers",
    element: <AnalyticsSuppliersPage />,
  },
  {
    path: "balance",
    element: <BalancePage />,
  },
  {
    path: "unloading/:marketplaceId",
    element: <UnloadingAllPage />,
  },
  {
    path: "unloading/:marketplaceId/link/:unloadingId",
    element: (
      <EditUnloading>
        <UnloadingOnePage />
      </EditUnloading>
    ),
  },
  {
    path: "unloading/:marketplaceId/link/add",
    element: (
      <CreateUnloading>
        <UnloadingOnePage />
      </CreateUnloading>
    ),
  },
];

export const CRMMainRouterConfig = (crm: string) => [
  {
    path: "*",
    loader: ({ request }: { request: any }) => {
      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      return redirect(rootPath + "/category");
    },
  },
  {
    path: process.env.NODE_ENV === "development" ? "/:crm/" : "/",
    element: <CRMMainLayout />,
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(rootPath + "/category");
      }

      return null;
    },
    children: routes(crm),
  },
];
