import {
  TableValuesBody,
  TableValuesHead,
  TableValuesItem,
  TableValuesWrapper,
} from "./components";

export function TableValues() {
  return (
    <TableValuesWrapper>
      <>
        <TableValuesHead />
        <TableValuesBody>
          {(data) => <TableValuesItem key={data.id} value={data} />}
        </TableValuesBody>
      </>
    </TableValuesWrapper>
  );
}
