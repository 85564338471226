import { Link } from "react-router-dom";
import { ForgotPasswordForm } from "@forms";

import style from "./ForgotPasswordPage.module.scss";

export function ForgotPasswordPage() {
  return (
    <main className={style["page"]}>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>Відновлення паролю</h3>
        <p className={style["message"]}>
          Введіть вашу E-mail адресу для <br /> відновлення паролю
        </p>
        <ForgotPasswordForm />
        <div className="flex items-center justify-center">
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/auth/login"
                : "/auth/login"
            }
            className={style["backLink"]}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
            >
              <path
                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM15 3.5L1 3.5V4.5L15 4.5V3.5Z"
                fill="#4674B8"
              />
            </svg>
            <span className={style["link"]}>Назад</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
