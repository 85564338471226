import { HoroshopCategory, MarketplaceId, ProductHoroshop, UnloadingExtended, UnloadingLink } from "@interfaces/unloading";
import { api } from "..";
import { Pagination } from "@interfaces/shared";
import Category from "@interfaces/category";
import { PartialExcept } from "store/store";

const unloadingApi = api.injectEndpoints({
    endpoints: (build) => ({
        unloadingDelete: build.mutation<void, {
            marketplaceId: MarketplaceId,
            links: UnloadingLink["id"][]
        }>({
            query: ({ marketplaceId, links }) => {
                let listItem = links.map((id) => `items=${id}`).join("&");

                return {
                    url: `unloadings/horoshop?marketplace_id=${marketplaceId}&${listItem}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        api.util.updateQueryData(
                            "unloadingAll",
                            data.marketplaceId,
                            (draft) => (draft.filter(
                                (item) => !data.links.includes(item.id),
                            )),
                        ),
                    );
                } catch { }
            },
        }),
        unloadingCreate: build.mutation<UnloadingExtended, PartialExcept<Omit<UnloadingExtended, "id">, "name" | "categories" | "file_format"> >({
            query: (data) => {
                return {
                    url: "unloadings/horoshop",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["UnloadingList"],
        }),
        unloadingUpdate: build.mutation<UnloadingExtended, PartialExcept<UnloadingExtended, "name" | "categories" | "file_format">>({
            query: (data) => {
                return {
                    url: "unloadings/horoshop/" + data.id,
                    method: "PUT",
                    body: data
                }
            },
            invalidatesTags: ["UnloadingList"],
        }),
        unloadingsHoroshopCategoriesSet: build.mutation<Pagination<{ products: ProductHoroshop[] }>, {
            unloading_id: UnloadingExtended["id"];
            product_ids: ProductHoroshop["id"][];
            categories: HoroshopCategory["name"][];
            offset: Pagination<void>["offset"];
            limit: Pagination<void>["limit"];
            search?: string;
            selectedProductCategoriesIds: Category["id"][];
        }>({
            query: (data) => {
                return {
                    url: "unloadings/horoshop_categories",
                    method: "PUT",
                    body: data
                }
            },
            async onQueryStarted({ selectedProductCategoriesIds, limit, offset, search }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.upsertQueryData(
                            "productsAllHoroshop",
                            { categoriesIds: selectedProductCategoriesIds, limit, offset, search },
                            data,
                        ),
                    );
                } catch { }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useUnloadingAllQuery,
    useUnloadingOneQuery,
    useProductsAllHoroshopQuery,
    useUnloadingDeleteMutation,
    useUnloadingCreateMutation,
    useUnloadingUpdateMutation,
    useUnloadingsHoroshopCategoriesSetMutation
} = unloadingApi;