import { useField, useFormikContext } from "formik";
import { RxCrossCircled } from "react-icons/rx";
import { TextareaProps } from "../..";
import cn from "classnames";

import style from "./Textarea.module.scss";

export function Textarea({
  formikName,
  label,
  helperText,
  placeholder,
}: TextareaProps) {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(formikName);

  return (
    <div className={style["container"]}>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div
        className={cn(
          style["textField"],
          meta.touched && meta.error ? style["error_on"] : style["error_off"],
        )}
      >
        <textarea
          {...field}
          style={{ minHeight: "46px" }}
          className={style["input"]}
          autoComplete="off"
          rows={4}
          placeholder={placeholder || ""}
        />
        <div className={style["clearButton"]}>
          {values[field.name] ? (
            <RxCrossCircled
              className={style["icon"]}
              onClick={() => {
                setFieldValue(field.name, "");
              }}
            />
          ) : null}
        </div>
      </div>
      {meta.touched && meta.error ? (
        helperText ? (
          helperText(meta.error || "")
        ) : (
          <p className={style["errorMessage"]}>{meta.error || ""}</p>
        )
      ) : null}
    </div>
  );
}
