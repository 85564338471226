import style from "./HistoryBalanceWrapper.module.scss";

export function HistoryBalanceWrapper({ children }: { children: JSX.Element }) {
  return (
    <div className={style["container"]}>
      <div className={style["title"]}>
        <div className="hidden w-full rounded-t-[6px] bg-white max-sm:block"></div>
        <div className="bg-white max-sm:flex-shrink-0">
          <div className={style["label"]}>Історія балансу</div>
        </div>
        <div className="hidden w-full rounded-t-[6px] bg-white max-sm:block"></div>
      </div>
      <div className={style["content"]}>
        <div className={style["gridContainer"]}>{children}</div>
      </div>
    </div>
  );
}
