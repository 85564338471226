import { CurrentBalance, HistoryBalance, TopPanel } from "components/balance";
import style from "./BalancePage.module.scss";
import { PaymentMethodModal } from "@modals";

export function BalancePage() {
  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={style["flexContainer"]}>
        <CurrentBalance />
        <HistoryBalance />
      </div>
      <PaymentMethodModal />
    </main>
  );
}
