import { useAppSelector } from "store/hooks";
import { Core, CreateMod, EditMod } from "./components";
import { useParams } from "react-router-dom";

export function CharacteristicsSettings() {
  const { variationId } = useParams();

  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );

  if (!variationId) {
    if (authenticProductObject) {
      if (authenticProductObject.product.characteristics) {
        return (
          <EditMod
            dataProductCharacterictics={
              authenticProductObject.product.characteristics
            }
          >
            {(selectebleProductData) => <Core data={selectebleProductData} />}
          </EditMod>
        );
      } else {
        return null;
      }
    } else {
      return (
        <CreateMod>
          {(selectebleTemplateData) => {
            if (selectebleTemplateData.length > 0) {
              return <Core data={selectebleTemplateData} />;
            } else {
              return <></>;
            }
          }}
        </CreateMod>
      );
    }
  } else {
    return null;
  }
}
