import { UnloadingsContent, TopPanel } from "components/catalog/unloadings";
import style from "./UnloadingAllPage.module.scss";
import { DeleteLinkModal } from "@modals";

export function UnloadingAllPage() {
  return (
    <main className={style["layout"]}>
      <TopPanel />
      <UnloadingsContent />
      <DeleteLinkModal />
    </main>
  );
}
