import { RxCrossCircled } from "react-icons/rx";
import { useSearchParams } from "react-router-dom";
import { SearchInputProps } from "../..";

import style from "./Search.module.scss";

export function SearchInput({ placeholder, onSearch }: SearchInputProps) {
  const [queryParams] = useSearchParams();

  return (
    <div className={style["container"]}>
      <input
        className={style["input"]}
        type="text"
        placeholder={placeholder}
        value={queryParams.get("search") || ""}
        onChange={(e) => {
          onSearch(e.target.value);
        }}
      />
      <div className={style["clearButton"]}>
        {queryParams.get("search") ? (
          <RxCrossCircled
            className={style["icon"]}
            onClick={() => {
              onSearch("");
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
