import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import style from "./DeleteTenantForm.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { axiosAuth } from "@api";
import { setDeleteTenant, setTenant } from "@global-state/superadmin/slice";
import { Input, SubmitButton } from "@ui";

export function DeleteTenantForm({
  setIsSuccess,
}: {
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("success");

  const [initialValues, setInitialValues] = useState<{ name: string }>({
    name: "",
  });

  const activeModal = useAppSelector((state) => state.modalState.modalName);
  const tenant_delete = useAppSelector(
    (state) => state.temporalData.tenant.delete,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (submitingStatus === "success" && !activeModal) {
      setSubmitingStatus("idle");
    }
    if (activeModal === "DeleteTenantModal") {
      setInitialValues({
        name: "",
      });
    } else {
      setInitialValues({
        name: "-",
      });
    }
  }, [activeModal]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: initialValues.name,
      }}
      onSubmit={(values, actions) => {
        setSubmitingStatus("load");

        axiosAuth("https://account-api.levelup.com.ua")
          .delete("/account/tenant?uuid=" + tenant_delete?.uuid)
          .then((response) => {
            setSubmitingStatus("success");
            setIsSuccess(true);
            dispatch(setTenant(response.data.tenants));
            dispatch(setDeleteTenant(response.data.deleted_tenants));
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
          });
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mb-[40px]">
            <Input
              formikName="name"
              label={
                <p className={style["inputLabel"]}>
                  Для підтвердження введіть:{" "}
                  <span className={style["subdomain"]}>
                    {tenant_delete?.subdomain}
                  </span>
                </p>
              }
              inputType="text"
            />
          </div>
          <SubmitButton
            type="submit"
            disabled={
              submitingStatus === "load" ||
              values.name !== tenant_delete?.subdomain
            }
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Видалити"
            successText="Успішно видалено"
            errorText="Не вдалось видалити"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
