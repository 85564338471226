import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import cn from "classnames";

import style from "./MenuBooksGroups.module.scss";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { Button, SpoilerButton } from "@ui";
import {
  referenceBook_deleteGroup_setData,
  referenceBook_editGroup_setData,
} from "@global-state/temporal-data/slice";
import { showModal } from "@global-state/modal-state/slice";

export function MenuBooksGroups() {
  const { data } = useReferenceBookAllQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { group } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  useEffect(() => {
    if (data && data.length > 0) {
      const listIdGroup = data.reduce<number[]>(
        (arr, group) => [...arr, group.id],
        [],
      );

      if (!listIdGroup.includes(+(group || "-1"))) {
        navigate(crm + "/reference-book/" + data[0].id);
      }
    }
    if (data && data.length === 0) {
      if (
        location.pathname !== crm + "/reference-book/" &&
        location.pathname !== crm + "/reference-book"
      ) {
        navigate(crm + "/reference-book");
      }
    }
  }, [location, data]);

  return (
    <div className={style["sidebar"]}>
      <div className={style["label"]}>Групи довідників</div>
      <div
        className={style["mobileLabel"]}
        onClick={() => setIsOpen((state) => !state)}
      >
        <p>Групи довідників</p>
        <SpoilerButton
          isOpen={isOpen}
          className={style["customSpoilerButton"]}
        />
      </div>
      <div
        className={cn(style["groupsBox"], !isOpen && style["groupsBox_close"])}
      >
        <nav className={cn(style["menu"], "scroll")}>
          {data?.map((item) => (
            <div key={item.id}>
              <div
                className={cn(
                  style["group"],
                  item.id === Number(group) && style["group_active"],
                )}
              >
                <Link
                  title={item.group}
                  className={style["link"]}
                  to={crm + "/reference-book/" + item.id}
                >
                  {item.group}
                </Link>
                <div className={style["actions"]}>
                  <Button
                    type="edit"
                    onClick={() => {
                      dispatch(
                        referenceBook_editGroup_setData({
                          id: item.id,
                          group: item.group,
                        }),
                      );
                      dispatch(
                        showModal({
                          name: "EditBookGroupModal",
                        }),
                      );
                    }}
                  />
                  <Button
                    type="delete"
                    onClick={() => {
                      dispatch(referenceBook_deleteGroup_setData(item.id));
                      dispatch(
                        showModal({
                          name: "DeleteBookGroupModal",
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div className={style["divider"]}></div>
            </div>
          ))}
        </nav>
        <div className={style["button"]}>
          <Button
            type="add"
            size="big"
            variant="outlined"
            onClick={() => {
              dispatch(
                showModal({
                  name: "AddBookGroupModal",
                }),
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
