import { Variation } from "@interfaces/products-template";
import { VariationButtons, VariationCheckbox } from "./components";

import style from "./ItemVariation.module.scss";

export function ItemVariation({ variation }: { variation: Variation }) {
  return (
    <div className={style["variation"]}>
      <div className={style["box"]}>
        <VariationCheckbox variation={variation} />
        <p className={style["value"]}>{variation.name}</p>
      </div>
      <VariationButtons variation={variation} />
    </div>
  );
}
