import { useAppSelector } from "store/hooks";
import { Core, CreateMod } from "./components";

export function VariationsSettings() {
  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );

  if (!authenticProductObject) {
    return (
      <CreateMod>
        {(selectebleTemplateData) => {
          if (selectebleTemplateData.length > 0) {
            return <Core data={selectebleTemplateData} />;
          } else {
            return <></>;
          }
        }}
      </CreateMod>
    );
  } else {
    return null;
  }
}
