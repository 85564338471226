import {
  addSelectProduct,
  removeSelectProduct,
} from "@global-state/unloading/slice";
import { ProductHoroshop } from "@interfaces/unloading";
import { Checkbox } from "@ui";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function ProductCheckbox({ product }: { product: ProductHoroshop }) {
  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeSelectProduct(product.id));
    };
  }, []);

  return (
    <Checkbox
      checked={selectProduct.includes(product.id)}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(addSelectProduct(product.id));
        } else {
          dispatch(removeSelectProduct(product.id));
        }
      }}
    />
  );
}
