import axios from "axios";

export function axiosAuth(
  baseURL: string,
  accessToken: string = "access_token",
  refreshToken: string = "refresh_token",
) {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    config.adapter = ["xhr", "http", "https"];
    config.headers.Authorization =
      window.localStorage.getItem(accessToken) || "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const result = await refresh(baseURL, refreshToken);

        if (typeof result === "string") {
          window.localStorage.setItem(accessToken, result);
          originalRequest.headers.Authorization = result;

          return axios(originalRequest);
        } else {
          if (result === 401) {
            return Promise.reject("logaut");
          }
        }
      } else {
        console.log(error.response.status);
      }
      return Promise.reject(error);
    },
  );

  return instance;
}

async function refresh(
  baseURL: string,
  refreshToken: string = "refresh_token",
): Promise<number | string> {
  let result: string | number = 0;

  await axios({
    method: "post",
    url: `${baseURL}/auth/refresh_token`,
    headers: {
      Authorization: window.localStorage.getItem(refreshToken) || "",
    },
  })
    .then((response) => {
      result = response.data.access_token as string;
    })
    .catch(({ response }) => {
      result = response.status as number;
    });

  return result;
}
