import { useParams } from "react-router-dom";
import { CurrentTemplate } from "..";
import style from "./TopPanel.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import { showModal } from "@global-state/modal-state/slice";

export function TopPanel() {
  const dispatch = useAppDispatch();
  const { id: idTemplate } = useParams();

  return (
    <div className={style["topPanel"]}>
      <CurrentTemplate />
      <div></div>
      <div className={style["button"]}>
        <Button
          type="add"
          size="big"
          text={idTemplate ? "Додати параметр" : "Додати шаблон"}
          onClick={() => {
            if (idTemplate) {
              dispatch(
                showModal({
                  name: "AddParameterModal",
                }),
              );
            } else {
              dispatch(
                showModal({
                  name: "AddTemplateModal",
                }),
              );
            }
          }}
        />
      </div>
    </div>
  );
}
