import { BalanceHistoryRecord } from "@interfaces/balance";
import style from "./HistoryBalanceItem.module.scss";

export function HistoryBalanceItem({ data }: { data: BalanceHistoryRecord }) {
  return (
    <div className={style["gridRow"]}>
      <div className={style["dateCell"]}>{formatDate(data.change_date)}</div>
      {data.transaction_type === "top_up" ? (
        <>
          <div className={style["balanceUp"]}>Надходження</div>
          <div className={style["amountUp"]}>+ $ {data.change_amount}</div>
        </>
      ) : (
        <>
          <div className={style["balanceDown"]}>Списання</div>
          <div className={style["amountDown"]}>- $ {data.change_amount}</div>
        </>
      )}
    </div>
  );
}

function formatDate(isoDate: string) {
  const date = new Date(isoDate);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${day}.${month}.${year}`;
}
