import { useCallback, useEffect, useRef, useState } from "react";
import style from "./VerifyForm.module.scss";
import { useAppDispatch } from "store/hooks";
import { Timer } from "components/common/Timer";
import cn from "classnames";
import { axiosLog } from "@api";
import { RiLoader5Fill } from "react-icons/ri";
import { SubmitButton } from "@ui";
import { setSuperadminAll } from "@global-state/superadmin/slice";
import { setSuperadminAllDTO } from "@global-state/superadmin/dto/setSuperadminAll.dto";

export function VerifyForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");
  const [resendCodeStatus, setResendCodeStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");
  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);
  const [timerEnd, setTimerEnd] = useState<boolean>(false);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const dispatch = useAppDispatch();

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 6);
    const newCode = [...code];
    for (let i = 0; i < pastedData.length; i++) {
      newCode[i] = pastedData[i];
      inputRefs.current[i]?.focus();
    }
    setCode(newCode);
  };

  useEffect(() => {
    if (timerEnd) {
      setSubmitingStatus({
        errorText: "",
        status: "failure",
      });
      setResendCodeStatus({
        errorText: "Час верифікації вийшов",
        status: "failure",
      });
    }
  }, [timerEnd]);

  const setEnd = useCallback(() => setTimerEnd(true), []);

  return (
    <div className={style["container"]}>
      <div className={style["inputsBox"]}>
        {[...Array(6)].map((_, index) => (
          <input
            className={style["input"]}
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            maxLength={1}
            value={code[index]}
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
          />
        ))}
      </div>
      <Timer setEnd={setEnd} />
      <div className={style["buttonsBox"]}>
        <button
          className={cn(
            style["resendCodeButton"],
            resendCodeStatus === "idle" && style["uninitialized"],
            resendCodeStatus === "success" && style["success"],
            resendCodeStatus === "load" && style["loading"],
            typeof resendCodeStatus === "object" && style["error"],
          )}
          disabled={resendCodeStatus !== "idle"}
          onClick={() => {
            setResendCodeStatus("load");

            axiosLog("https://account-api.levelup.com.ua")
              .post("/auth/resend_code", {
                service_token: window.localStorage.getItem("service_token"),
              })
              .then((response) => {
                if (response.data.ok) {
                  setResendCodeStatus("success");

                  setTimeout(() => {
                    setResendCodeStatus("idle");
                  }, 10000);
                }
              })
              .catch((err) => {
                setResendCodeStatus({
                  status: "failure",
                  errorText:
                    err?.response?.data?.error || "Непередбачувана помилка",
                });

                setTimeout(() => {
                  setResendCodeStatus("idle");
                }, 10000);
              });
          }}
        >
          <div className={style["buttonContent"]}>
            {resendCodeStatus === "load" ? (
              <div className={style["loader"]}>
                <RiLoader5Fill className={style["icon"]} />
              </div>
            ) : (
              <span className={style["buttonText"]}>
                {resendCodeStatus === "idle" && "Відправити код ще раз"}
                {resendCodeStatus === "success" && "Код відправлено"}
                {typeof resendCodeStatus === "object" &&
                  resendCodeStatus.errorText}
              </span>
            )}
          </div>
        </button>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="button"
            disabled={submitingStatus === "load" || timerEnd}
            hFull
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Далі"
            successText="Успішно веривіковано"
            errorText="Помилка"
            errorMesage=""
            onClick={() => {
              setSubmitingStatus("load");

              axiosLog("https://account-api.levelup.com.ua")
                .post("/auth/verify", {
                  service_token: window.localStorage.getItem("service_token"),
                  verification_code: code.join(""),
                })
                .then((response) => {
                  if (response.data.ok) {
                    window.localStorage.removeItem("service_token");
                    window.localStorage.setItem(
                      "access_token",
                      response.data.access_token,
                    );
                    window.localStorage.setItem(
                      "refresh_token",
                      response.data.refresh_token,
                    );

                    setSubmitingStatus("success");
                    dispatch(
                      setSuperadminAll(setSuperadminAllDTO(response.data)),
                    );
                  }
                })
                .catch((err) => {
                  setSubmitingStatus({
                    status: "failure",
                    errorText:
                      err?.response?.data?.error || "Непередбачувана помилка",
                  });
                  console.error(err);

                  setTimeout(() => {
                    setSubmitingStatus("idle");
                  }, 10000);
                });
            }}
          />
        </div>
      </div>
      {typeof submitingStatus === "object" ? (
        <p className={style["errorMessage"]}>{submitingStatus.errorText}</p>
      ) : null}
    </div>
  );
}
