import { HoroshopCategory } from "@interfaces/unloading";
import { api } from "..";

const horoshopCategoriesApi = api.injectEndpoints({
    endpoints: (build) => ({
        horoshopCategoryCreate: build.mutation<HoroshopCategory, HoroshopCategory["name"]>({
            query: (data) => {
                return {
                    url: "horoshop/categories/history",
                    method: "POST",
                    body: {
                        name: data
                    }
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.updateQueryData(
                            "horoshopCategoriesAll",
                            undefined,
                            (draft) => {
                                Object.assign(draft, data);
                            },
                        ),
                    );
                } catch { }
            },
        }),
        horoshopCategoryUpdate: build.mutation<HoroshopCategory[], HoroshopCategory>({
            query: (data) => {
                return {
                    url: "horoshop/categories/history",
                    method: "PUT",
                    body: data
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.upsertQueryData(
                            "horoshopCategoriesAll",
                            undefined,
                            data
                        ),
                    );
                } catch { }
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useHoroshopCategoriesAllQuery,
    useHoroshopCategoryCreateMutation,
    useHoroshopCategoryUpdateMutation
} = horoshopCategoriesApi;