import { ControlPanel, Pagination, Table } from "./components";
import style from "./ProductsTable.module.scss";

export function ProductsTable() {
  return (
    <div className={style["container"]}>
      <ControlPanel />
      <Table />
      <Pagination />
    </div>
  );
}
