import { Form, Formik } from "formik";
import * as Yup from "yup";

import style from "./ResetPasswordCRMForm.module.scss";
import { useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { axiosLog } from "@api";
import { setCRMUserAll } from "@global-state/CRMUser/slice";
import { setCRMUserAllDTO } from "@global-state/CRMUser/dto/SetCRMUserAll.dto";
import { Input, SubmitButton } from "@ui";

export function ResetPasswordCRMForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const [searchParams] = useSearchParams();
  const { token } = useParams();

  const dispatch = useAppDispatch();
  const passwordRef = useRef<string>("");

  const crm = window.localStorage.getItem("subdomain");

  return (
    <Formik
      initialValues={{
        password: "",
        repeatPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Пароль - обов'язкове поле"),
        repeatPassword: Yup.string()
          .test(
            "is-equal",
            "Паролі не співпадають",
            (value, context) => value === passwordRef.current,
          )
          .required("Підтвердження нового паролю - обов'язкове поле"),
      })}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog(`https://${crm}.api.levelup.com.ua`)
          .post(
            "/dropers/auth/reset-password",
            {
              email: searchParams.get("email"),
              password: values.password,
              token: token,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response.data.ok) {
              window.localStorage.setItem(
                "access_token_crm",
                response.data.access_token,
              );
              window.localStorage.setItem(
                "refresh_token_crm",
                response.data.refresh_token,
              );
              setSubmitingStatus("success");

              dispatch(setCRMUserAll(setCRMUserAllDTO(response.data)));
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText: err?.response?.data?.error || "Error",
            });
            console.error(err);
          });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input
            formikName="password"
            label="Новий пароль"
            inputType="password"
            passwordRef={passwordRef}
          />
          <Input
            formikName="repeatPassword"
            label="Підтвердження нового паролю"
            inputType="password"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
