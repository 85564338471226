import { useEffect, useState } from "react";
import { SpoilerButton } from "../SpoilerButton";
import cn from "classnames";

import style from "./Select.module.scss";

interface Value {
  id: number;
  value: string;
  label: string;
}

export function SelectNoFormik({
  currentValue,
  setCurrentValue,
  listValue,
  label,
  className,
}: {
  currentValue: Value;
  setCurrentValue: (newValue: Value) => void;
  listValue: Value[];
  label: JSX.Element | string;
  className?: string;
}) {
  const [showListValue, setShowListValue] = useState<boolean>(false);

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowListValue(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div className={className}>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div className="relative">
        <div
          data-select
          className={cn(
            style["container"],
            showListValue && "!border-input-main",
          )}
          onClick={() => {
            setShowListValue((state) => !state);
          }}
        >
          <input
            className={style["input"]}
            type="text"
            value={currentValue.label}
            readOnly
          />
          <SpoilerButton isOpen={showListValue} />
        </div>
        <div
          className={cn(
            style["listValues"],
            showListValue ? "flex" : "hidden",
            "scroll",
          )}
        >
          {listValue.map((item) => (
            <div
              key={item.id}
              className={cn(
                style["value"],
                item.value === currentValue.value && style["value_active"],
              )}
              data-value={item.value}
              onClick={() => {
                setShowListValue(false);
                setCurrentValue(item);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
