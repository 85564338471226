import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "../../store/store";
import ProductsTemplate, {
  Parameter,
} from "../../interfaces/products-template";
import ReferenceBookGroup, {
  ReferenceBook,
  ReferenceBookValue,
} from "../../interfaces/refence-book";
import Tenant from "../../interfaces/tenant";
import Category from "../../interfaces/category";
import {
  ProductExtended,
  ProductTable,
} from "../../interfaces/product";
import { UnloadingLink } from "@interfaces/unloading";

const initialState: {
  reference_book: {
    delete_books: { id: ReferenceBook["id"]; tag: "single" | "multiple" }[];
    edit_books: {
      id: ReferenceBook["id"];
      name: ReferenceBook["name"];
      group_id: ReferenceBookGroup["id"];
      tag: "single" | "multiple";
    }[];

    delete_group: ReferenceBookGroup["id"];
    edit_group: {
      id: ReferenceBookGroup["id"];
      group: ReferenceBookGroup["group"];
    } | null;

    delete_values: {
      id: ReferenceBookValue["id"];
      tag: "single" | "multiple";
    }[];
    edit_values: {
      id: ReferenceBookValue["id"];
      name: ReferenceBookValue["name"];
      tag: "single" | "multiple";
    }[];
  };

  products_template: {
    delete: { id: ProductsTemplate["id"]; tag: "single" | "multiple" }[];
    edit: Pick<ProductsTemplate, "id" | "name"> | null;
  };

  parameters: {
    delete_characteristic: {
      id: Parameter["id"];
      tag: "single" | "multiple";
    }[];
    delete_variation: { id: Parameter["id"]; tag: "single" | "multiple" }[];
    edit: Parameter | null;
  };

  tenant: {
    delete: Tenant | null;
  };

  category: {
    add: Category["id"] | null;
    delete: Category | null;
    edit: Category | null;
  };

  product: {
    delete: { id: ProductTable["id"]; tag: "single" | "multiple" }[];
    selected_variation: {
      childIds: { id: ProductExtended["id"], tag: "single" | "multiple" }[];
      parentId: ProductExtended["id"];
    };
  };

  unloading: {
    delete: UnloadingLink["id"]
  };

  balance: string;
} = {
  reference_book: {
    delete_books: [],
    edit_books: [],

    delete_group: -1,
    edit_group: null,

    delete_values: [],
    edit_values: [],
  },

  products_template: {
    delete: [],
    edit: null,
  },

  parameters: {
    delete_characteristic: [],
    delete_variation: [],
    edit: null,
  },

  tenant: {
    delete: null,
  },

  category: {
    add: -1,
    delete: null,
    edit: null,
  },

  product: {
    delete: [],
    selected_variation: {
      childIds: [],
      parentId: -1,
    },
  },

  unloading: {
    delete: -1
  },

  balance: ""
};

const temporalDataSlice = createSlice({
  initialState,
  name: "temporalData",
  reducers: {
    referenceBook_deleteBooks_addData: (
      state,
      action: SliceAction<
        { id: ReferenceBook["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.reference_book.delete_books = [
        ...state.reference_book.delete_books,
        ...action.payload,
      ];
    },
    referenceBook_deleteBooks_setData: (
      state,
      action: SliceAction<
        { id: ReferenceBook["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.reference_book.delete_books = action.payload;
    },
    referenceBook_deleteBooks_deleteData: (
      state,
      action: SliceAction<{ id: ReferenceBook["id"]; singleOnly: boolean }>,
    ) => {
      state.reference_book.delete_books =
        state.reference_book.delete_books.filter((item) => {
          if (action.payload.singleOnly) {
            return item.id !== action.payload.id || item.tag !== "single";
          } else {
            return item.id !== action.payload.id;
          }
        });
    },
    referenceBook_deleteBooks_clearData: (state) => {
      state.reference_book.delete_books = [];
    },

    referenceBook_editBooks_addData: (
      state,
      action: SliceAction<
        {
          id: ReferenceBook["id"];
          name: ReferenceBook["name"];
          group_id: ReferenceBookGroup["id"];
          tag: "single" | "multiple";
        }[]
      >,
    ) => {
      state.reference_book.edit_books = [
        ...state.reference_book.edit_books,
        ...action.payload,
      ];
    },
    referenceBook_editBooks_setData: (
      state,
      action: SliceAction<
        {
          id: ReferenceBook["id"];
          name: ReferenceBook["name"];
          group_id: ReferenceBookGroup["id"];
          tag: "single" | "multiple";
        }[]
      >,
    ) => {
      state.reference_book.edit_books = action.payload;
    },
    referenceBook_editBooks_deleteData: (
      state,
      action: SliceAction<{ id: ReferenceBook["id"]; singleOnly: boolean }>,
    ) => {
      state.reference_book.edit_books = state.reference_book.edit_books.filter(
        (item) => {
          if (action.payload.singleOnly) {
            return item.id !== action.payload.id || item.tag !== "single";
          } else {
            return item.id !== action.payload.id;
          }
        },
      );
    },
    referenceBook_editBooks_clearData: (state) => {
      state.reference_book.edit_books = [];
    },

    referenceBook_deleteGroup_setData: (
      state,
      action: SliceAction<ReferenceBookGroup["id"]>,
    ) => {
      state.reference_book.delete_group = action.payload;
    },
    referenceBook_deleteGroup_clearData: (state) => {
      state.reference_book.delete_group = -1;
    },

    referenceBook_editGroup_setData: (
      state,
      action: SliceAction<Omit<ReferenceBookGroup, "items">>,
    ) => {
      state.reference_book.edit_group = action.payload;
    },
    referenceBook_editGroup_clearData: (state) => {
      state.reference_book.edit_group = null;
    },

    referenceBook_deleteValues_addData: (
      state,
      action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
    ) => {
      state.reference_book.delete_values = [
        ...state.reference_book.delete_values,
        ...action.payload,
      ];
    },
    referenceBook_deleteValues_setData: (
      state,
      action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
    ) => {
      state.reference_book.delete_values = action.payload;
    },
    referenceBook_deleteValues_deleteData: (
      state,
      action: SliceAction<{ id: number; singleOnly: boolean }>,
    ) => {
      state.reference_book.delete_values =
        state.reference_book.delete_values.filter((item) => {
          if (action.payload.singleOnly) {
            return item.id !== action.payload.id || item.tag !== "single";
          } else {
            return item.id !== action.payload.id;
          }
        });
    },
    referenceBook_deleteValues_clearData: (state) => {
      state.reference_book.delete_values = [];
    },

    referenceBook_editValues_addData: (
      state,
      action: SliceAction<
        {
          id: number;
          name: Record<string, string>;
          tag: "single" | "multiple";
        }[]
      >,
    ) => {
      state.reference_book.edit_values = [
        ...state.reference_book.edit_values,
        ...action.payload,
      ];
    },
    referenceBook_editValues_setData: (
      state,
      action: SliceAction<
        {
          id: number;
          name: Record<string, string>;
          tag: "single" | "multiple";
        }[]
      >,
    ) => {
      state.reference_book.edit_values = action.payload;
    },
    referenceBook_editValues_deleteData: (
      state,
      action: SliceAction<{ id: number; singleOnly: boolean }>,
    ) => {
      state.reference_book.edit_values =
        state.reference_book.edit_values.filter((item) => {
          if (action.payload.singleOnly) {
            return item.id !== action.payload.id || item.tag !== "single";
          } else {
            return item.id !== action.payload.id;
          }
        });
    },
    referenceBook_editValues_clearData: (state) => {
      state.reference_book.edit_values = [];
    },

    productsTemplate_delete_addData: (
      state,
      action: SliceAction<
        { id: ProductsTemplate["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.products_template.delete = [
        ...state.products_template.delete,
        ...action.payload,
      ];
    },
    productsTemplate_delete_setData: (
      state,
      action: SliceAction<
        { id: ProductsTemplate["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.products_template.delete = action.payload;
    },
    productsTemplate_delete_deleteData: (
      state,
      action: SliceAction<{ id: ProductsTemplate["id"]; singleOnly: boolean }>,
    ) => {
      state.products_template.delete = state.products_template.delete.filter(
        (item) => {
          if (action.payload.singleOnly) {
            return item.id !== action.payload.id || item.tag !== "single";
          } else {
            return item.id !== action.payload.id;
          }
        },
      );
    },
    productsTemplate_delete_clearData: (state) => {
      state.products_template.delete = [];
    },

    productsTemplate_edit_setData: (
      state,
      action: SliceAction<Pick<ProductsTemplate, "id" | "name">>,
    ) => {
      state.products_template.edit = action.payload;
    },
    productsTemplate_edit_clearData: (state) => {
      state.products_template.edit = null;
    },

    parameters_delete_addData: (
      state,
      action: SliceAction<{
        data: { id: Parameter["id"]; tag: "single" | "multiple" }[];
        parameterType: "characteristic" | "variation";
      }>,
    ) => {
      if (action.payload.parameterType === "characteristic") {
        state.parameters.delete_characteristic = [
          ...state.parameters.delete_characteristic,
          ...action.payload.data,
        ];
      } else {
        state.parameters.delete_variation = [
          ...state.parameters.delete_variation,
          ...action.payload.data,
        ];
      }
    },
    parameters_delete_setData: (
      state,
      action: SliceAction<{
        data: { id: Parameter["id"]; tag: "single" | "multiple" }[];
        parameterType: "characteristic" | "variation";
      }>,
    ) => {
      if (action.payload.parameterType === "characteristic") {
        state.parameters.delete_characteristic = action.payload.data;
      } else {
        state.parameters.delete_variation = action.payload.data;
      }
    },
    parameters_delete_deleteData: (
      state,
      action: SliceAction<{
        data: { id: Parameter["id"]; singleOnly: boolean };
        parameterType: "characteristic" | "variation";
      }>,
    ) => {
      if (action.payload.parameterType === "characteristic") {
        state.parameters.delete_characteristic =
          state.parameters.delete_characteristic.filter((item) => {
            if (action.payload.data.singleOnly) {
              return (
                item.id !== action.payload.data.id || item.tag !== "single"
              );
            } else {
              return item.id !== action.payload.data.id;
            }
          });
      } else {
        state.parameters.delete_variation =
          state.parameters.delete_variation.filter((item) => {
            if (action.payload.data.singleOnly) {
              return (
                item.id !== action.payload.data.id || item.tag !== "single"
              );
            } else {
              return item.id !== action.payload.data.id;
            }
          });
      }
    },
    parameters_delete_clearData: (
      state,
      action: SliceAction<"characteristic" | "variation">,
    ) => {
      if (action.payload === "characteristic") {
        state.parameters.delete_characteristic = [];
      } else {
        state.parameters.delete_variation = [];
      }
    },

    parameters_edit_setData: (state, action: SliceAction<Parameter>) => {
      state.parameters.edit = action.payload;
    },
    parameters_edit_clearData: (state) => {
      state.parameters.edit = null;
    },

    tenant_delete_setData: (state, action: SliceAction<Tenant>) => {
      state.tenant.delete = action.payload;
    },
    tenant_delete_clearData: (state) => {
      state.tenant.delete = null;
    },

    category_add_setData: (
      state,
      action: SliceAction<Category["id"] | null>,
    ) => {
      state.category.add = action.payload;
    },

    category_delete_setData: (state, action: SliceAction<Category>) => {
      state.category.delete = action.payload;
    },

    category_edit_setData: (state, action: SliceAction<Category>) => {
      state.category.edit = action.payload;
    },

    product_delete_addData: (
      state,
      action: SliceAction<
        { id: ProductTable["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.product.delete = [...state.product.delete, ...action.payload];
    },
    product_delete_setData: (
      state,
      action: SliceAction<
        { id: ProductTable["id"]; tag: "single" | "multiple" }[]
      >,
    ) => {
      state.product.delete = action.payload;
    },
    product_delete_deleteData: (
      state,
      action: SliceAction<{ id: ProductTable["id"]; singleOnly: boolean }>,
    ) => {
      state.product.delete = state.product.delete.filter((item) => {
        if (action.payload.singleOnly) {
          return item.id !== action.payload.id || item.tag !== "single";
        } else {
          return item.id !== action.payload.id;
        }
      });
    },
    product_delete_clearData: (state) => {
      state.product.delete = [];
    },

    product_selected_variation_addData: (
      state,
      action: SliceAction<{
        childIds: { id: ProductExtended["id"], tag: "single" | "multiple" }[];
        parentId: ProductExtended["id"];
      }>,
    ) => {
      state.product.selected_variation = {
        parentId: action.payload.parentId,
        childIds: [...state.product.selected_variation.childIds, ...action.payload.childIds]
      };
    },
    product_selected_variation_deleteData: (state, action: SliceAction<{ id: ProductExtended["id"]; singleOnly: boolean }>) => {
      state.product.selected_variation.childIds = state.product.selected_variation.childIds.filter((item) => {
        if (action.payload.singleOnly) {
          return item.id !== action.payload.id || item.tag !== "single";
        } else {
          return item.id !== action.payload.id;
        }
      });
    },
    product_selected_variation_setData: (
      state,
      action: SliceAction<{
        childIds: { id: ProductExtended["id"], tag: "single" | "multiple" }[];
        parentId: ProductExtended["id"];
      }>,
    ) => {
      state.product.selected_variation = action.payload;
    },
    product_selected_variation_clearData: (state) => {
      state.product.selected_variation = {
        childIds: [],
        parentId: -1,
      }
    },

    unloading_delete_setData: (
      state,
      action: SliceAction<UnloadingLink["id"]>,
    ) => {
      state.unloading.delete = action.payload;
    },
    unloading_delete_clearData: (state) => {
      state.unloading.delete = -1;
    },

    setBalanceTopUpValue: (state, action: SliceAction<string>) => {
      state.balance = action.payload;
    },
    clearBalanceTopUpValue: (state) => {
      state.balance = "";
    },

    clearAllTemporalData: (state) => {
      state.reference_book = {
        delete_books: [],
        edit_books: [],

        delete_group: -1,
        edit_group: null,

        delete_values: [],
        edit_values: [],
      };

      state.products_template = {
        delete: [],
        edit: null,
      };

      state.parameters = {
        delete_characteristic: [],
        delete_variation: [],
        edit: null,
      };

      state.tenant = {
        delete: null,
      };

      state.category = {
        add: -1,
        delete: null,
        edit: null,
      };

      state.product = {
        delete: [],
        selected_variation: {
          childIds: [],
          parentId: -1,
        },
      };

      state.unloading = {
        delete: -1
      }

      state.balance = ""
    },
  },
});

const { reducer, actions } = temporalDataSlice;

export default reducer;

export const {
  referenceBook_deleteBooks_addData,
  referenceBook_deleteBooks_setData,
  referenceBook_deleteBooks_deleteData,
  referenceBook_deleteBooks_clearData,

  referenceBook_editBooks_addData,
  referenceBook_editBooks_setData,
  referenceBook_editBooks_deleteData,
  referenceBook_editBooks_clearData,

  referenceBook_deleteGroup_setData,
  referenceBook_deleteGroup_clearData,

  referenceBook_editGroup_setData,
  referenceBook_editGroup_clearData,

  referenceBook_deleteValues_addData,
  referenceBook_deleteValues_setData,
  referenceBook_deleteValues_deleteData,
  referenceBook_deleteValues_clearData,

  referenceBook_editValues_addData,
  referenceBook_editValues_setData,
  referenceBook_editValues_deleteData,
  referenceBook_editValues_clearData,

  productsTemplate_delete_addData,
  productsTemplate_delete_setData,
  productsTemplate_delete_deleteData,
  productsTemplate_delete_clearData,

  productsTemplate_edit_setData,
  productsTemplate_edit_clearData,

  parameters_delete_addData,
  parameters_delete_setData,
  parameters_delete_deleteData,
  parameters_delete_clearData,

  parameters_edit_setData,
  parameters_edit_clearData,

  tenant_delete_setData,
  tenant_delete_clearData,

  category_add_setData,

  category_delete_setData,

  category_edit_setData,

  product_delete_addData,
  product_delete_setData,
  product_delete_deleteData,
  product_delete_clearData,

  product_selected_variation_setData,
  product_selected_variation_addData,
  product_selected_variation_deleteData,
  product_selected_variation_clearData,

  unloading_delete_setData,
  unloading_delete_clearData,

  setBalanceTopUpValue,
  clearBalanceTopUpValue,

  clearAllTemporalData,
} = actions;
